.tempdeliverymodal {
    // border: 1px solid red;
    position: fixed;
    background-color: var(--modal-bg);
    // background: red;
    inset: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0px !important;
    > div {
        height: 300px;
        width: 500px;
        background: var(--white);
        border-radius: var(--border-radius-20);
        border: 1px solid var(--black);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        .body {
            // height: 100%;
            display: flex;
            flex-direction: column;
            // justify-content: space-around;
            > div {
                margin-bottom: 10px;
            }
        }
        .btn {
            align-self: flex-end;
            width: 258px;
        }
    }
}
