.clientfilter {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
  background: var(--modal-bg);
  z-index: 100;
  .clientfilter_main {
    background: var(--white);
    width: 334px;
    height: 436px;
    border: 1px solid var(--black);
    border-radius: 12px;
    padding: 25px 22px 22px 38px;
    display: flex;
    flex-direction: column;

    .header {
      margin: 0 0 0 auto;
      width: fit-content;
    }
    .body {
      width: 191px;
      //   display: flex;
      //   flex-direction: column;
      //   column-gap: 14px;
      //   flex-basis: 0 100% auto;
      //   max-height: 100%;
      > div:not(:first-child) {
        margin-top: 14px;
      }
      .title {
        margin-bottom: 6px;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.9975px;
      }
      .cust_inp_checkbox {
        display: flex;
        column-gap: 11px;
        align-items: center;
        .cust_checkbox {
          height: 15px;
          width: 15px;
          border: 1px solid var(--black);
          border-radius: 2px;
          position: relative;
          overflow: hidden;
          &:after {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            background: var(--black);
            transform: scale(0);
            border-radius: 50%;
            transition: transform 0.3s linear;
          }
          &.active {
            &:after {
              transform: scale(1.6);
            }
          }
        }
      }
    }
    .footer {
      margin: auto 0 0 auto;
      width: fit-content;
      .btn {
        width: 120px;
        height: 31px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
