.accordian {
  position: relative;
  margin: 0 0 0 0;
  .accordian_header {
    cursor: pointer;
    font-size: var(--f30);
    line-height: var(--f39);
    padding-bottom: 15px;
    border-bottom: 1px solid var(--black);
    // font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      margin-right: 6px;
      transition: transform 0.3s linear;
    }
  }
  &:not(:first-child) {
    .accordian_header {
      margin-top: 20px;
    }
  }
  .accordian_body {
    height: 0px;
    overflow: hidden;
    // background: var(--black);
    transition: height 0.3s linear;
    > div {
      padding-top: 25px;
      padding-bottom: 5px;
    }
    &.accordian_body_active {
      height: 200px;
    }
  }
}
