.addclientmodal {
  position: fixed;
  inset: 0;
  background: var(--modal-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    width: 334px;
    // height: 562px;
    max-height: calc(100vh - 100px);
    padding: 26px 22px 26px 38px;
    background: var(--white);
    border-radius: var(--border-radius-12);
    border: 1px solid var(--black);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    .header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      img {
        cursor: pointer;
      }
    }
    .body {
      padding-right: 16px;
      .title {
        font-weight: 700;
        font-size: var(--f16);
        line-height: var(--lh21);
      }
      input {
        margin-top: 6px;
        width: 100%;
        height: 31px;
        padding-inline: 10px;
        font-weight: 500;
        font-size: var(--f14);
        line-height: var(--lh18);
        border: 1px solid var(--grey30);
        border-radius: var(--border-radius-4);
        &::placeholder {
          color: var(--black);
        }
      }
      .mt-15 {
        margin-top: 15px;
      }
      .mt-20 {
        margin-top: 20px;
      }
    }
    .footer {
      padding-right: 16px;

      margin-top: auto;
      margin-left: auto;
      .btn {
        border-radius: var(--border-radius-4);
        padding: 0;
        height: 31px;
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: var(--f14);
        line-height: var(--lh18);
      }
    }
  }
}
