.vendor {
  .vendor_search_and_add {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 14px;
    // .searchandfilterbar {
    //   // width: calc(100% - 214px);
    // }
    .add_vendor_btn {
      width: 200px;
      cursor: pointer;
      height: 38px;
      background: var(--black);
      color: var(--white);
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: var(--f24);
      line-height: var(--lh31);
      column-gap: 12px;
      border-radius: var(--border-radius-6);
    }
  }
  .vendor_table_to_view {
    display: flex;
    height: calc(100vh - 225px - 50px);
    .vendor_modal_options {
      width: 0;
      flex-grow: 1;
      overflow: hidden;
      // height: 100%;
      // background: turquoise;
    }
  }
  .vendor_table_menudots {
    position: relative;
    svg {
      max-width: 15px;
      max-height: 15px;
    }
    .vendor_table_menu {
      position: absolute;
      top: 0;
      right: 30px;
      width: 174px;
      height: 130px;
      background: var(--white);
      border: 1px solid var(--black);
      border-radius: var(--border-radius-10);
      padding: 14px 13px 21px 23px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      transform-origin: top;
      transform: scaleY(0);
      opacity: 0;
      transition: transform 0.2s linear, opacity 0.1s 0.1s linear;
      &.active {
        transform: scaleY(1);
        opacity: 1;
      }
      .header {
        display: flex;
        justify-content: flex-end;
        margin: 0 0 16px 0;
      }
      .body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        .items {
          cursor: pointer;
          display: flex;
          align-items: center;
          column-gap: 18px;
          font-size: var(--f12);
          line-height: var(--f16);
          div {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
