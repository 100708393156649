.switcher_main {
  --height_px: calc(var(--switcher_height) * 1px);
  --width_px: calc(var(--switcher_width) * 1px);
  --transition_timer: 0.3s;
  cursor: pointer;
  //   background: var(--black);
  background-image: linear-gradient(90deg, var(--black) 50%, var(--white) 50%);
  background-size: calc(var(--width_px) * 3) var(--height_px);
  background-position: 0 0;
  height: calc(var(--height_px) + 2px);
  width: calc(var(--width_px) + 2px);
  border-radius: calc((var(--height_px) + 2px) / 2);
  padding: 2px;
  // box-shadow: 0 0 0 1px var(--black);
  border: 1px solid var(--black);
  transition: background var(--transition_timer) linear;
  > .switcher_blob {
    height: calc(var(--height_px) - 4px);
    width: calc(var(--height_px) - 4px);
    //   background: var(--white);
    background-image: linear-gradient(
      -90deg,
      var(--black) 50%,
      var(--white) 50%
    );
    background-size: calc(var(--width_px) * 3) var(--height_px);
    background-position: 0 0;
    border-radius: 50%;
    transition: transform var(--transition_timer) linear,
      background var(--transition_timer) linear;
    transform: translateX(calc(var(--width_px) - var(--height_px)));
  }
  &.active {
    // background: var(--white);
    background-position: 100% 0;
    > .switcher_blob {
      //   background: var(--black);
      background-position: 100% 0;
      transform: translateX(0);
    }
  }
}
