.order_filter_modal {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--modal-bg);
  z-index: 10;
  > .filter_modal {
    padding: 25.54px 16.06px 22px 38px;
    background-color: var(--white);
    width: 298px;
    min-height: 436px;
    border-radius: var(--border-radius-12);
    border: 1px solid var(--black);
    display: flex;
    flex-direction: column;
    > .filter_modal_header {
      text-align: right;
      img {
        margin-left: auto;
      }
    }
    > .filter_modal_body {
      padding-right: 53px;
      > .paid_unpaid_toggeler {
        // display: flex;
        justify-content: space-between;
        font-size: var(--f14);
        line-height: var(--lh18);
        font-weight: 500;
        margin-bottom: 23px;
        .input_custom {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          input {
            display: none;
            &:checked ~ .checkbox_custom {
              &:after,
              &:before {
                opacity: 1;
              }
            }
          }
          .checkbox_custom {
            height: 15px;
            width: 15px;
            border: 1px solid var(--black);
            border-radius: 2px;
            position: relative;
            margin-right: 11px;
            // &:after,
            &:before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              right: 0;
              margin: auto;
              height: calc(100%);
              width: calc(100%);
              background: var(--black);
              opacity: 0;
            }
            // &:after {
            //     transform: rotate(45deg);
            // }
            &:before {
              transform: rotate(0deg);
            }
          }
        }
      }
      .title {
        font-size: var(--f16);
        line-height: var(--21);
        font-weight: 700;
        margin-bottom: 6px;
      }
      .status_selector {
        font-size: var(--f14);
        line-height: var(--lh18);
        font-weight: 500;
        .cust_dropdown {
          position: relative;
          .default_show {
            display: flex;
            align-items: center;
            width: 100%;
            padding-block: 7px 6px;
            padding-left: 10px;
            border: 1px solid var(--black);
            border-radius: var(--border-radius-4);
            img {
              margin-left: auto;
              margin-right: 11px;
            }
          }
          .dropdown_selection_menu {
            top: 100%;
            left: 0;
            width: 100%;
            position: absolute;
            height: 0px;
            overflow: hidden;
            background-color: var(--black);
            color: var(--white);
            border-radius: var(--border-radius-4);
            // padding: 10px;
            transition: 0.4s all linear;
            > div {
              padding: 5px 10px;
            }
          }
        }
      }
      .date_selector {
        margin-top: 14px;
      }
      .order_selector {
        margin-top: 14px;
        > div:last-child {
          margin-top: 6px;
        }
      }
    }
    > .filter_modal_footer {
      margin-top: auto;
      margin-left: auto;
      padding: 20px 0 0 0;
      .btn {
        padding-block: 8px 7px;
        width: 120px;
      }
    }
  }
}
