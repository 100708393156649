.team_delete {
  position: fixed;
  inset: 0;
  background: var(--modal-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    width: 464px;
    height: 400px;
    background: var(--white);
    border: 1px solid var(--black);
    border-radius: var(--border-radius-12);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .header {
      position: absolute;
      top: 32px;
      right: 31px;
    }
    .title {
      font-weight: 700;
      font-size: 30px;
      line-height: 39px;
      text-align: center;
      margin: 0 0 31px 0;
    }
    .btn_group {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 279px;
      .btn {
        border: 1px solid var(--black);
      }
    }
  }
}
