.team_page {
  padding-top: 54px !important;
  // padding-right: 284px !important;
  .team_body {
    .team_search_and_add {
      display: flex;
      column-gap: 14px;
      .searchandfilterbar {
        width: calc(100% - 200px - 14px);
      }
      .add_employee {
        cursor: pointer;
        width: 200px;
        height: 40px;
        background: var(--black);
        border-radius: var(--border-radius-6);
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: var(--white);
        font-size: var(--f24);
        line-height: var(--lh31);
        letter-spacing: 0.9975px;
        column-gap: 14px;
      }
    }
    .team_menu {
      position: relative;
      .team_menu_main {
        width: 200px;
        height: 170px;
        background: var(--white);
        position: absolute;
        top: 0;
        right: calc(100% + 20px);
        border: 1px solid var(--black);
        border-radius: var(--border-radius-10);
        padding: 14px 13px 21px 23px;
        .team_menu_header {
          height: 9px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin: 0 0 16px 0;
        }
      }
      .team_menu_body {
        > div {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          column-gap: 19px;
          font-weight: 500;
          font-size: var(--f12);
          line-height: var(--f16);
          letter-spacing: 1.14625px;
          &:not(:last-child) {
            margin: 0 0 15px 0;
          }
          svg {
            height: 15px;
            max-width: 15px;
          }
        }
      }
    }
  }
}
