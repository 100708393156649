.accordiancards {
    display: flex;
    flex-wrap: wrap;
    column-gap: 16px;
    // margin-right: -1px;
    .cards {
        border: 1px solid var(--grey30);
        width: 137px;
        height: 66px;
        padding-inline: 7px;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        border-radius: var(--border-radius-6);
        font-weight: 500;
        font-size: var(--f16);
        line-height: var(--lh21);
        > div {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &.add_card {
            cursor: pointer;
            border-color: var(--black);
            background: var(--black);
        }
    }
}
