.orders {
  .order_table_body {
    > .search_and_filter_bar {
      display: flex;
      // height: 40px;
      .filter_img_div {
        display: flex;
        justify-content: center;
        align-items: center;

        height: auto;
      }
      .search_input {
        border: 1.12791px solid var(--grey30);
        border-radius: var(--border-radius-6);
        overflow: hidden;
        width: 100%;
        display: flex;
        align-items: center;
        img {
          margin-left: 14.16px;
          margin-right: 23.3px;
        }
        > input {
          height: 38px;
          width: 100%;
          border: 0;

          padding: 0px;
          &::placeholder {
            color: var(--grey40);
          }
        }
        margin-right: 11px;
      }
    }
    .read_barcode {
      border-radius: 5px;
      border: 1.12791px solid var(--grey30);
      // background: red;
      height: calc(100% - 21px);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .filter_btn_group {
      display: flex;
      .filter_card {
        margin-top: 24px;
        background: var(--black);
        // width: 138px;
        height: 35px;
        border-radius: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-inline: 20px 13px;
        color: var(--white);
        font-weight: 500;
        font-size: var(--f13);
        line-height: var(--lh17);
        margin-right: 8px;
        svg {
          margin-left: 9px;
        }
      }
    }
    .order_table {
      margin-top: 23.12px;
    }
    .read_barcode_tags {
      //   background: red;
      display: flex;
      flex-direction: column;
      height: 100vh;
      padding: 20px;
      input {
        width: 100%;
        // height: 31px;
        border: 1px solid var(--grey30);
        padding: 6px 10px;
        border-radius: var(--border-radius-4);
        font-weight: 500;
        font-size: var(--f14);
        line-height: var(--lh18);
        color: var(--black);
        // height: calc(100% - 21px);
        &.inp_error {
          border-color: red;
        }
      }
      .btn {
        // margin: 10px 0 0 0;
        padding: 10px 20px;
        white-space: nowrap;
      }
      .search_order_group {
        display: flex;
        gap: 10px;
      }
    }
    .input_error_txt {
      color: red;
      font-weight: 700;
      font-size: 14px;
      padding: 10px 0;
    }
    .order_details {
      background: var(--black);
      color: var(--white);
      padding: 20px;
      border-radius: 15px;
      font-weight: 500;
      > div {
        // &:nth-child(1) {
        //   display: flex;
        // }
        &:nth-child(2) {
          font-size: var(--f30);
          line-height: var(--lh39);
          // margin-bottom: 14px;
        }
      }
    }
    .tag_colections {
      margin: 20px 0 0 0;
      // border: 1px solid #aeaeae;
      overflow-y: scroll;
      flex-grow: 1;
      .tag_cust_select {
        width: 100%;
        height: 48px;
        border-radius: var(--border-radius-6);
        border: 1px solid var(--grey30);
        padding-inline: 17px;
        font-size: var(--f16);
        line-height: var(--lh21);
        font-weight: 500;
      }
      .tag_cust_select {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // background: url("../../../img/icons/arrow_down_icon.svg");
        // background-repeat: no-repeat;
        // background-position: calc(100% - 17px);
        // background-size: 24px;
        // image-rendering: crisp-ed;
        position: relative;
        .tag_id_item_dropdown {
          z-index: 100;
          position: absolute;
          top: 100%;
          left: 0px;
          width: 100%;
          // background: var(--grey90);
          color: var(--white);
          border-radius: var(--border-radius-6);
          overflow: hidden;
          height: 0px;
          transition: height 0.3s linear;
          border: 1px solid var(--grey50);
          &[active="false"] {
            border: 1px solid transparent;
          }
          > div {
            height: 55px;
            padding: 10px 17px;
            transition: all 0.3s linear;
            cursor: pointer;
            background: var(--grey90);
            // opacity: 0.6;
            &:not(:last-child) {
              border-bottom: 1px solid var(--grey50);
            }
            &:hover {
              background: var(--black);
              // opacity: 1;
            }
          }
        }
      }
      .tag_id_body {
        // width: calc(100% - 27px);
        width: 100%;
        column-gap: 20px;
        display: flex;
        position: relative;
        > .tag_delete_btn {
          cursor: pointer;
          width: fit-content;
          position: absolute;
          left: calc(100% + 10px);
          //   top: 0;
          //   bottom: 0;
          //   margin: auto;
          height: fit-content;
          width: fit-content;
          //   transform: translateY(100%);
          &.tag_print_btn {
            top: 3px;
            left: calc(50% - 30px);
            // transform: translateY(-100%);
          }
        }
        .tag_id_item_part3 {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          > div {
            &:last-child {
              width: 100%;
            }
            &:not(:last-child) {
              // width: 50%;
              font-size: var(--f10);
              line-height: var(--lh12);
              color: var(--grey40);
            }
            &:first-child {
              margin-right: 5px;
            }
          }
        }
        .tag_id_item_part2 {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          > div {
            &:last-child {
              width: 100%;
            }
            &:not(:last-child) {
              // width: 50%;
              font-size: var(--f10);
              line-height: var(--lh12);
              color: var(--grey40);
            }
            &:first-child {
              margin-right: 5px;
            }
          }
        }
        > div {
          width: 50%;
        }
        position: relative;
        margin-bottom: 22px;
      }
      .tag_id_add_img {
        // position: absolute;
        // right: -37px;
        // right: 0;
        // left: 0;
        // margin: 0 auto;
        width: 100%;
        // top: 0;
        // width: fit-content;
        display: flex;
        justify-content: center;
        img {
          width: 30px;
        }
        // margin-block: 22px;
      }
      .create_tag_ids {
        overflow-y: scroll;
        height: 100%;
        // height: calc(100vh - 100px);
        // margin-bottom: 10px;
        // margin-right: -27px;
      }
      .tag_id_body {
        margin-top: auto;
        // &:not(:first-child) {
        //     margin-top: 19px;
        // }
      }

      .tags_btn_group {
        margin-top: auto;
        display: flex;
        column-gap: 20px;
        > div {
          cursor: pointer;
          width: 50%;
        }
      }
    }
    .read_barcode_modal {
      position: fixed;
      inset: 0;
      background: var(--modal-bg);
      display: flex;
      justify-content: center;
      align-items: center;
      > div {
        padding: 25.54px 16.06px 22px 38px;
        background-color: var(--white);
        width: 298px;
        height: fit-content;
        // max-height: 436px;
        border-radius: var(--border-radius-12);
        border: 1px solid var(--black);
        display: flex;
        flex-direction: column;
        .header {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
