.pickupfailed {
  position: absolute;
  //   inset: 0;
  z-index: 100;
  top: 0;
  right: 40px;
  background: var(--modal-bg);
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  //   > div {
  border-radius: 10px;
  padding: 20px 10px;
  background: var(--white);
  //   width: 150px;
  border: 1px solid var(--black);
  .header {
    text-align: end;
    //   margin-bottom: 10px;
    height: 15px;
    svg {
      height: 10px;
      transform: translateY(-100%);
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .menu_items {
      display: flex;
      align-items: center;
      font-size: 13px;
      line-height: 15px;
      svg {
        height: 15px !important;
        width: 15px !important;
        // width: auto;
      }
    }
  }
  //   }
}
