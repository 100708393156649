.tempdraftmodal {
    // border: 1px solid red;
    position: fixed;
    background-color: var(--modal-bg);
    // background: red;
    inset: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0px !important;
    > div {
        height: 300px;
        width: 500px;
        background: var(--white);
        border-radius: var(--border-radius-20);
        border: 1px solid var(--black);
        padding: 32px 31px 40px 38px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .header {
            svg {
                position: absolute;
                right: 31px;
                top: 32px;
            }
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            // justify-content: space-around;
            .cust_input {
                width: 258px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid var(--grey40);
                border-radius: var(--border-radius-4);
                padding: 5px 10px;
                > div {
                    margin-inline: 5px;
                }
                &.cust_delivered {
                    cursor: pointer !important;
                    border: 0;
                    input {
                        width: fit-content;
                    }
                    label {
                        flex-grow: 1;
                        margin-left: 5px;
                    }
                }
            }
            .margin_top_auto {
                margin-top: auto;
            }
            input {
                border: 0;
                width: 100%;
            }
            > * {
                margin-top: 6px;
            }
        }
        .btn {
            align-self: flex-end;
            width: 258px;
        }
    }
}
