.layout {
  > div:not(.navbar) {
    // > div {
    padding-right: 136px;
    padding-left: 137px;
    padding-top: 46px;
    height: 100vh;
    @media screen and (max-width: 500px) {
      // padding-left: 45px;
      // padding-right: 45px;
      padding: 0 !important
      ;
    }
    // }
    > .pagetitle {
      position: fixed;
      right: 10px;
      top: 24px;
      font-size: 60px;
      line-height: 78px;
      width: 78px;
      height: 78px;
      font-weight: 700;
      color: var(--black);
      @media screen and (max-width: 500px) {
        display: none;
      }
      // border: 1px solid red;
      > div {
        white-space: nowrap;
        // border: 1px solid red;
        // transform-origin: left bottom;
        transform: rotate(90deg);
        position: relative;
        .orders_download {
          transform: rotate(-90deg);
          position: absolute;
          left: 268.88px;
          top: 0;
          bottom: 0;
          margin: auto;
          background: #000;
          border-radius: 5px;
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .discounts_download {
          transform: rotate(-90deg);
          position: absolute;
          left: 350px;
          top: 0;
          bottom: 0;
          margin: auto;
          background: #000;
          border-radius: 5px;
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
