.checkpassword {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  //   z-index: 1000;
  > div {
    width: 334px;
    height: 436px;
    background: var(--white);
    border: 1px solid var(--black);
    border-radius: 12px;
    padding: 41px 26px 22px 38px;
    position: relative;
    display: flex;
    flex-direction: column;
    .header {
      position: absolute;
      top: 25px;
      right: 22px;
      //   margin: 0 0 0 auto;
      //   width: fit-content;
    }
    .footer {
      margin: auto 0 0 0;
    }
    .title {
      margin: 0 0 6px 0;
      font-weight: 700;
      font-size: var(--f16);
      line-height: var(--lh21);
      letter-spacing: 0.9975px;
    }
    .cust_inp_password {
      border: 1px solid #000;
      border-radius: 4px;
      width: 189px;
      height: 29px;
      overflow: hidden;
      margin: 0 0 14px 0;
      display: flex;
      align-items: center;
      //   padding: 0 50px 0 0;
      position: relative;
      svg {
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 0;
        margin: auto 0;
        // border: 1px solid red;
        // margin-right: 10px;
      }
      input {
        border: 0;
        padding: 0 10px;
        width: calc(100% - 30px);
      }
    }
    .btn {
      width: fit-content;
      padding: 0 10px;
      height: 31px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: var(--f12);
      line-height: var(--lh16);
      letter-spacing: 0.9975px;
      margin: 0 0 0 auto;
    }
  }
}
