.add_faq {
  position: fixed;
  inset: 0;
  background: var(--modal-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  > div {
    width: fit-content;
    height: fit-content;
    background: var(--white);
    border: 1px solid var(--black);
    border-radius: 12px;
    padding: 32px 34px 24px 38px;
    position: relative;
    .header {
      > svg {
        position: absolute;
        right: 24px;
        top: 25px;
        z-index: 10;
      }
    }
    .body {
      width: 800px;
      padding: 0 8px 0 0;
      font-size: 16px;
      line-height: 17px;
      .title {
        font-weight: 700;
        font-size: var(--f30);
        line-height: var(--lh39);
      }
      .body_question_title {
        margin: 26px 0 6px 0;
        font-weight: 700;
        // font-size: var(--f16);
        // line-height: var(--lh21);
      }
      .cust_inp {
        border: 1px solid var(--black);
        border-radius: var(--border-radius-4);
        > input {
          border-radius: var(--border-radius-4);
          border: 0;
          width: 100%;
          padding: 16px 19px;
        }
      }
      //
      input {
        width: 100%;
        width: 0;
        padding: 0;
        margin: 0;
        border: 0;
      }
      label {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .show_cursor {
        position: relative;
        width: fit-content;
        &::after {
          position: absolute;
          content: "";
          height: 100%;
          width: 1px;
          background: var(--black);
          top: 0;
          right: 0;
          opacity: 1;
          animation: infinite 0.8s flicker;
          @keyframes flicker {
            0%,
            100% {
              opacity: 1;
            }
            50% {
              opacity: 0;
            }
          }
        }
        &[is_default="true"]::after {
          right: unset;
          left: 0;
        }
      }
      h1,
      h2,
      h3 {
        margin: 0;
        line-height: 1;
      }
      ul,
      ol {
        margin: 10px 0 10px 0;
      }
      .ques {
        display: flex;
        margin: 26px 0 20px 0;
        // font-size: var(--f18);
        // line-height: var(--lh22);
        // font-weight: 700;
        overflow: visible;
        .ques_q {
          width: 15px;
          margin: 60px 10px 0 0;
        }
      }
      .ans {
        display: flex;
        margin: 26px 0 20px 0;
        font-weight: 400;
        // font-size: var(--f14);
        // line-height: var(--lh18);
        .ans_a {
          width: 15px;
          margin: 60px 10px 0 0;
        }
      }
    }
    .footer {
      display: flex;
      column-gap: 10px;
      > div {
        flex-grow: 1;
      }
    }
    // ?preview
    // overflow: hidden;
    transition: all 0.3s linear;
  }
}
