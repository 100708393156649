.tick_switcher {
  border-radius: var(--border-radius-6);
  border: 1px solid var(--current_color);
  position: relative;
  &[state="visible"] {
    --current_color: var(--green);
  }
  &[state="hidden"] {
    --current_color: var(--red);
  }
  &::after,
  &::before {
    content: "";
    position: absolute;
    background: var(--current_color);
    height: 80%;
    width: 5px;
    inset: 0;
    margin: auto;
  }
  &::after {
    transform: rotate(45deg);
  }
}
.loader_icon {
  // background: var(--modal-bg);
  > path {
    stroke: var(--black);
    $divide_by: 1;
    $dashlen: calc(184 / ($divide_by * 2));
    stroke-dasharray: $dashlen;
    animation: loader_icon 3s infinite linear;
    @keyframes loader_icon {
      0% {
        stroke-dashoffset: 0;
      }
      100% {
        stroke-dashoffset: calc($dashlen * $divide_by * 2);
      }
    }
  }
}
