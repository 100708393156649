.futurepredictions_body {
  margin-left: 48px;
  margin-right: 43px;
  height: calc(100% - 32px);
  width: 100%;
  // border: 1px solid red;
  display: flex;
  flex-direction: column;
  // column-gap: 10px;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  > div {
    // width: 50%;
    width: 100%;
    // flex-grow: 1;
    // border: 1px solid var(--grey30);
    // height: 200px;
    border-radius: var(--border-radius-10);
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    overflow: hidden;
    padding: 0 0 10px 0;
    .title {
      font-weight: 700;
      background: var(--black);
      color: var(--white);
      margin: 0 0 auto 0;
      padding: 10px 20px;
      display: flex;
      align-items: center;
    }
    > div {
      padding: 0 20px;
    }
    &.lifetime_trend,
    &.current_trend {
      // border: 1px solid red;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      > div {
        &.title {
          width: 100%;
        }
        &:not(:first-child) {
          // border: 1px solid var(--grey30);
          // margin: 0 0 0 30px;
          width: calc(50% - 5px);
          padding: 0;
          border: 1px solid var(--black);
          padding: 20px;
          border-radius: var(--border-radius-10);
          > div {
            text-align: center;
            &:first-child {
              font-size: 15px;
            }
            &:last-child {
              font-size: 20px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
