.addorderstatus {
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  // column-gap: 10px;
  height: calc(100vh - 175px);
  .left_side {
    .status_tabs {
      display: flex;
      align-items: center;

      .status_orbs {
        height: 23px;
        width: 23px;
        border-radius: 50%;
        margin-right: 20px;
        position: relative;
        border: 1px solid var(--black);
        &:after {
          content: "";
          position: absolute;
          height: 100%;
          width: 1px;
          background: var(--black);
          top: 22px;
          left: 0;
          right: 0;
          margin: auto;
          z-index: -1;
        }
        &[state="0"] {
          background: var(--black);
        }
        &[state="1"] {
          background: var(--black);
          box-shadow: 0 0 0px 2px var(--white), 0 0 0px 3px var(--black);
        }
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      &:last-child .status_orbs:after {
        all: unset;
      }
      .status_type {
        width: calc(100% - 43px);
        font-size: var(--f16);
        line-height: var(--lh21);
        font-weight: 500;
        white-space: nowrap;
        display: flex;
        > div {
          &:first-child {
            overflow: hidden;
            text-overflow: ellipsis;
            width: calc(100% - 200px);
            // background: red;
          }
          &:last-child {
            text-align: right;
            // background: green;
            width: 200px;
          }
        }

        //   overflow: hidden;
      }
      .status_time {
        margin-left: auto;
      }
    }
  }
  .status_btn_group {
    margin-top: auto;
    display: flex;
    column-gap: 20px;

    > .btn {
      width: 50%;
    }
  }
}
