@charset "UTF-8";
:root {
  --white: #fff;
  --black: #000;
  --color1: #ccc;
  --color2: #aeaeae;
  --color3: #f0f0f0;
  --color4: #a6a6a6;
  --color5: #216ba5;
  --color6: #1d5d90;
  --color7: #b3b3b3;
  --color8: #3dcc4a;
  --color9: #32be3f;
  --color10: #2579ba;
  --color11: #216ba580;
  --color1: var(--white);
  --color2: var(--grey40);
  --color3: var(--black);
  --color5: var(--grey50);
  --color6: var(--black);
  --color7: red;
  --color10: var(--grey50);
  --color11: var(--grey40);
}
.react-datepicker__year-read-view--down-arrow {
  border-color: var(--color1);
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
  transform: rotate(135deg);
  right: -16px;
  top: 0;
}
.react-datepicker__month-read-view--down-arrow {
  border-color: var(--color1);
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
  transform: rotate(135deg);
  right: -16px;
  top: 0;
}
.react-datepicker__month-year-read-view--down-arrow {
  border-color: var(--color1);
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
  transform: rotate(135deg);
  right: -16px;
  top: 0;
}
.react-datepicker__navigation-icon {
  &::before {
    border-color: var(--color1);
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: "";
    display: block;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
  }
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}
.react-datepicker-popper[data-placement^="top"] {
  .react-datepicker__triangle {
    margin-left: -4px;
    position: absolute;
    width: 0;
    bottom: 0;
    margin-bottom: -8px;
    &::before {
      box-sizing: content-box;
      position: absolute;
      border: 8px solid transparent;
      height: 0;
      width: 1px;
      content: "";
      z-index: -1;
      border-width: 8px;
      left: -8px;
      border-bottom-color: var(--color2);
      border-bottom: none;
      border-top-color: var(--white);
      bottom: -1px;
      border-top-color: var(--color2);
    }
    &::after {
      box-sizing: content-box;
      position: absolute;
      border: 8px solid transparent;
      height: 0;
      width: 1px;
      content: "";
      z-index: -1;
      border-width: 8px;
      left: -8px;
      border-bottom: none;
      border-top-color: var(--white);
      bottom: 0;
    }
  }
  padding-bottom: 10px;
}
.react-datepicker-popper[data-placement^="bottom"] {
  .react-datepicker__triangle {
    margin-left: -4px;
    position: absolute;
    width: 0;
    top: 0;
    margin-top: -8px;
    &::before {
      box-sizing: content-box;
      position: absolute;
      border: 8px solid transparent;
      height: 0;
      width: 1px;
      content: "";
      z-index: -1;
      border-width: 8px;
      left: -8px;
      border-bottom-color: var(--color2);
      border-top: none;
      border-bottom-color: var(--color3);
      top: -1px;
      border-bottom-color: var(--color2);
    }
    &::after {
      box-sizing: content-box;
      position: absolute;
      border: 8px solid transparent;
      height: 0;
      width: 1px;
      content: "";
      z-index: -1;
      border-width: 8px;
      left: -8px;
      border-top: none;
      border-bottom-color: var(--color3);
      top: 0;
    }
  }
  padding-top: 10px;
}
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}
.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: var(--white);
  color: var(--black);
  border: 1px solid var(--color2);
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}
.react-datepicker--time-only {
  .react-datepicker__triangle {
    left: 35px;
  }
  .react-datepicker__time-container {
    border-left: 0;
  }
  .react-datepicker__time {
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  .react-datepicker__time-box {
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
}
.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}
.react-datepicker-popper {
  z-index: 1;
}
.react-datepicker-popper[data-placement="bottom-end"] {
  .react-datepicker__triangle {
    left: auto;
    right: 50px;
  }
}
.react-datepicker-popper[data-placement="top-end"] {
  .react-datepicker__triangle {
    left: auto;
    right: 50px;
  }
}
.react-datepicker-popper[data-placement^="right"] {
  padding-left: 8px;
  .react-datepicker__triangle {
    left: auto;
    right: 42px;
  }
}
.react-datepicker-popper[data-placement^="left"] {
  padding-right: 8px;
  .react-datepicker__triangle {
    left: 42px;
    right: auto;
  }
}
.react-datepicker__header {
  text-align: center;
  background-color: var(--color3);
  border-bottom: 1px solid var(--color2);
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
  &:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 0.3rem;
  }
}
.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
  &:not(.react-datepicker__header--time--only) {
    border-top-left-radius: 0;
  }
}
.react-datepicker__year-dropdown-container--select {
  display: inline-block;
  margin: 0 2px;
}
.react-datepicker__month-dropdown-container--select {
  display: inline-block;
  margin: 0 2px;
}
.react-datepicker__month-year-dropdown-container--select {
  display: inline-block;
  margin: 0 2px;
}
.react-datepicker__year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}
.react-datepicker__month-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}
.react-datepicker__current-month {
  margin-top: 0;
  color: var(--white);
  font-weight: bold;
  font-size: 0.944rem;
}
.react-datepicker-time__header {
  margin-top: 0;
  color: var(--white);
  font-weight: bold;
  font-size: 0.944rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.react-datepicker-year-header {
  margin-top: 0;
  color: var(--white);
  font-weight: bold;
  font-size: 0.944rem;
}
.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
  &:hover {
    * {
      &::before {
        border-color: var(--color4);
      }
    }
  }
}
.react-datepicker__navigation--previous {
  left: 2px;
}
.react-datepicker__navigation--next {
  right: 2px;
}
.react-datepicker__navigation--next--with-time {
  &:not(.react-datepicker__navigation--next--with-today-button) {
    right: 85px;
  }
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
}
.react-datepicker__navigation-icon--next {
  left: -2px;
  &::before {
    transform: rotate(45deg);
    left: -7px;
  }
}
.react-datepicker__navigation-icon--previous {
  right: -2px;
  &::before {
    transform: rotate(225deg);
    right: -7px;
  }
}
.react-datepicker__month-container {
  float: left;
}
.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
  .react-datepicker__year-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
}
.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}
.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
  .react-datepicker__month-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
  .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 2px;
  }
}
.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
  .react-datepicker-time__caption {
    display: inline-block;
  }
  .react-datepicker-time__input-container {
    display: inline-block;
    .react-datepicker-time__input {
      display: inline-block;
      margin-left: 10px;
      input {
        width: auto;
      }
      input[type="time"] {
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        -moz-appearance: textfield;
      }
    }
    .react-datepicker-time__delimiter {
      margin-left: 5px;
      display: inline-block;
    }
  }
}
.react-datepicker__time-container {
  float: right;
  border-left: 1px solid var(--color2);
  width: 85px;
  .react-datepicker__time {
    position: relative;
    background: white;
    border-bottom-right-radius: 0.3rem;
    .react-datepicker__time-box {
      width: 85px;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center;
      border-bottom-right-radius: 0.3rem;
      ul.react-datepicker__time-list {
        list-style: none;
        margin: 0;
        height: calc(195px + (1.7rem / 2));
        overflow-y: scroll;
        padding-right: 0;
        padding-left: 0;
        width: 100%;
        box-sizing: content-box;
        li.react-datepicker__time-list-item {
          height: 30px;
          padding: 5px 10px;
          white-space: nowrap;
          &:hover {
            cursor: pointer;
            background-color: var(--color3);
          }
        }
        li.react-datepicker__time-list-item--selected {
          background-color: var(--color5);
          color: white;
          font-weight: bold;
          &:hover {
            background-color: var(--color5);
          }
        }
        li.react-datepicker__time-list-item--disabled {
          color: var(--color1);
          &:hover {
            cursor: default;
            background-color: transparent;
          }
        }
      }
    }
  }
}
.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid var(--color2);
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}
.react-datepicker__week-number {
  color: var(--white) !important;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
  &:hover {
    border-radius: 0.3rem;
    background-color: var(--color3);
  }
}
.react-datepicker__day-names {
  white-space: nowrap;
  margin-bottom: -8px;
}
.react-datepicker__week {
  white-space: nowrap;
}
.react-datepicker__day-name {
  color: var(--black);
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
  color: var(--white);
}
.react-datepicker__day {
  color: var(--black);
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
  cursor: pointer;
  &:hover {
    border-radius: 0.3rem;
    background-color: var(--color3);
    color: var(--white);
  }
}
.react-datepicker__time-name {
  color: var(--black);
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__month--selected {
  border-radius: 0.3rem;
  background-color: var(--color5);
  color: var(--white);
  &:hover {
    background-color: var(--color6);
  }
}
.react-datepicker__month--in-selecting-range {
  border-radius: 0.3rem;
  background-color: var(--color5);
  color: var(--white);
  &:hover {
    background-color: var(--color6);
  }
}
.react-datepicker__month--in-range {
  border-radius: 0.3rem;
  background-color: var(--color5);
  color: var(--white);
  &:hover {
    background-color: var(--color6);
  }
}
.react-datepicker__quarter--selected {
  border-radius: 0.3rem;
  background-color: var(--color5);
  color: var(--white);
  &:hover {
    background-color: var(--color6);
  }
}
.react-datepicker__quarter--in-selecting-range {
  border-radius: 0.3rem;
  background-color: var(--color5);
  color: var(--white);
  &:hover {
    background-color: var(--color6);
  }
}
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: var(--color5);
  color: var(--white);
  &:hover {
    background-color: var(--color6);
  }
}
.react-datepicker__month--disabled {
  color: var(--color1);
  pointer-events: none;
  &:hover {
    cursor: default;
    background-color: transparent;
  }
}
.react-datepicker__quarter--disabled {
  color: var(--color1);
  pointer-events: none;
  &:hover {
    cursor: default;
    background-color: transparent;
  }
}
.react-datepicker__month-text {
  cursor: pointer;
  &:hover {
    border-radius: 0.3rem;
    background-color: var(--color3);
    color: var(--white);
    background-color: var(--color3);
  }
}
.react-datepicker__quarter-text {
  cursor: pointer;
  &:hover {
    border-radius: 0.3rem;
    background-color: var(--color3);
    color: var(--white);
    background-color: var(--color3);
  }
}
.react-datepicker__year-text {
  cursor: pointer;
  &:hover {
    border-radius: 0.3rem;
    background-color: var(--color3);
    color: var(--white);
  }
}
.react-datepicker__day--today {
  font-weight: bold;
}
.react-datepicker__month-text--today {
  font-weight: bold;
}
.react-datepicker__quarter-text--today {
  font-weight: bold;
}
.react-datepicker__year-text--today {
  font-weight: bold;
}
.react-datepicker__day--highlighted {
  border-radius: 0.3rem;
  background-color: var(--color8);
  color: var(--white);
  &:hover {
    background-color: var(--color9);
  }
}
.react-datepicker__month-text--highlighted {
  border-radius: 0.3rem;
  background-color: var(--color8);
  color: var(--white);
  &:hover {
    background-color: var(--color9);
  }
}
.react-datepicker__quarter-text--highlighted {
  border-radius: 0.3rem;
  background-color: var(--color8);
  color: var(--white);
  &:hover {
    background-color: var(--color9);
  }
}
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: var(--color8);
  color: var(--white);
  &:hover {
    background-color: var(--color9);
  }
}
.react-datepicker__day--highlighted-custom-1 {
  color: magenta;
}
.react-datepicker__month-text--highlighted-custom-1 {
  color: magenta;
}
.react-datepicker__quarter-text--highlighted-custom-1 {
  color: magenta;
}
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}
.react-datepicker__day--highlighted-custom-2 {
  color: green;
}
.react-datepicker__month-text--highlighted-custom-2 {
  color: green;
}
.react-datepicker__quarter-text--highlighted-custom-2 {
  color: green;
}
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}
.react-datepicker__day--selected {
  border-radius: 0.3rem;
  background-color: var(--color5);
  color: var(--white);
  &:hover {
    background-color: var(--color6);
  }
}
.react-datepicker__day--in-selecting-range {
  border-radius: 0.3rem;
  background-color: var(--color5);
  color: var(--white);
  &:hover {
    background-color: var(--color6);
  }
  &:not(.react-datepicker__day--in-range) {
    background-color: var(--color11);
  }
}
.react-datepicker__day--in-range {
  border-radius: 0.3rem;
  background-color: var(--color5);
  color: var(--white);
  &:hover {
    background-color: var(--color6);
  }
}
.react-datepicker__month-text--selected {
  border-radius: 0.3rem;
  background-color: var(--color5);
  color: var(--white);
  &:hover {
    background-color: var(--color6);
  }
}
.react-datepicker__month-text--in-selecting-range {
  border-radius: 0.3rem;
  background-color: var(--color5);
  color: var(--white);
  background-color: var(--color3);
  background-color: var(--color3);
  background-color: var(--color3);
  background-color: var(--color3);
  color: var(--black);
  color: var(--black);
  color: var(--black);
  color: var(--black);
  &:hover {
    background-color: var(--color6);
  }
  &:not(.react-datepicker__day--in-range) {
    background-color: var(--color11);
  }
}
.react-datepicker__month-text--in-range {
  border-radius: 0.3rem;
  background-color: var(--color5);
  color: var(--white);
  background-color: var(--color11);
  background-color: var(--color11);
  background-color: var(--color11);
  background-color: var(--color11);
  &:hover {
    background-color: var(--color6);
  }
}
.react-datepicker__quarter-text--selected {
  border-radius: 0.3rem;
  background-color: var(--color5);
  color: var(--white);
  &:hover {
    background-color: var(--color6);
  }
}
.react-datepicker__quarter-text--in-selecting-range {
  border-radius: 0.3rem;
  background-color: var(--color5);
  color: var(--white);
  background-color: var(--color3);
  background-color: var(--color3);
  background-color: var(--color3);
  background-color: var(--color3);
  color: var(--black);
  color: var(--black);
  color: var(--black);
  color: var(--black);
  &:hover {
    background-color: var(--color6);
  }
  &:not(.react-datepicker__day--in-range) {
    background-color: var(--color11);
  }
}
.react-datepicker__quarter-text--in-range {
  border-radius: 0.3rem;
  background-color: var(--color5);
  color: var(--white);
  background-color: var(--color11);
  background-color: var(--color11);
  background-color: var(--color11);
  background-color: var(--color11);
  &:hover {
    background-color: var(--color6);
  }
}
.react-datepicker__year-text--selected {
  border-radius: 0.3rem;
  background-color: var(--color5);
  color: var(--white);
  &:hover {
    background-color: var(--color6);
  }
}
.react-datepicker__year-text--in-selecting-range {
  border-radius: 0.3rem;
  background-color: var(--color5);
  color: var(--white);
  &:hover {
    background-color: var(--color6);
  }
  &:not(.react-datepicker__day--in-range) {
    background-color: var(--color11);
  }
}
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: var(--color5);
  color: var(--white);
  &:hover {
    background-color: var(--color6);
  }
}
.react-datepicker__day--keyboard-selected {
  border-radius: 0.3rem;
  background-color: var(--color10);
  color: var(--white);
  &:hover {
    background-color: var(--color6);
  }
}
.react-datepicker__month-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: var(--color10);
  color: var(--white);
  &:hover {
    background-color: var(--color6);
  }
}
.react-datepicker__quarter-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: var(--color10);
  color: var(--white);
  &:hover {
    background-color: var(--color6);
  }
}
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: var(--color10);
  color: var(--white);
  &:hover {
    background-color: var(--color6);
  }
}
.react-datepicker__year-text--in-range {
  background-color: var(--color11);
  background-color: var(--color11);
  background-color: var(--color11);
  background-color: var(--color11);
}
.react-datepicker__month--selecting-range {
  .react-datepicker__day--in-range {
    &:not(.react-datepicker__day--in-selecting-range) {
      background-color: var(--color3);
      color: var(--black);
    }
  }
  .react-datepicker__month-text--in-range {
    &:not(.react-datepicker__day--in-selecting-range) {
      background-color: var(--color3);
      color: var(--black);
    }
  }
  .react-datepicker__quarter-text--in-range {
    &:not(.react-datepicker__day--in-selecting-range) {
      background-color: var(--color3);
      color: var(--black);
    }
  }
  .react-datepicker__year-text--in-range {
    &:not(.react-datepicker__day--in-selecting-range) {
      background-color: var(--color3);
      color: var(--black);
    }
  }
}
.react-datepicker__year-text--in-selecting-range {
  background-color: var(--color3);
  background-color: var(--color3);
  background-color: var(--color3);
  background-color: var(--color3);
  color: var(--black);
  color: var(--black);
  color: var(--black);
  color: var(--black);
}
.react-datepicker__day--disabled {
  cursor: default;
  color: var(--color1);
  &:hover {
    background-color: transparent;
  }
}
.react-datepicker__month-text--disabled {
  cursor: default;
  color: var(--color1);
  &:hover {
    background-color: transparent;
  }
}
.react-datepicker__quarter-text--disabled {
  cursor: default;
  color: var(--color1);
  &:hover {
    background-color: transparent;
  }
}
.react-datepicker__year-text--disabled {
  cursor: default;
  color: var(--color1);
  &:hover {
    background-color: transparent;
  }
}
.react-datepicker__month-text.react-datepicker__month--selected {
  &:hover {
    background-color: var(--color5);
  }
}
.react-datepicker__month-text.react-datepicker__month--in-range {
  &:hover {
    background-color: var(--color5);
  }
}
.react-datepicker__month-text.react-datepicker__quarter--selected {
  &:hover {
    background-color: var(--color5);
  }
}
.react-datepicker__month-text.react-datepicker__quarter--in-range {
  &:hover {
    background-color: var(--color5);
  }
}
.react-datepicker__quarter-text.react-datepicker__month--selected {
  &:hover {
    background-color: var(--color5);
  }
}
.react-datepicker__quarter-text.react-datepicker__month--in-range {
  &:hover {
    background-color: var(--color5);
  }
}
.react-datepicker__quarter-text.react-datepicker__quarter--selected {
  &:hover {
    background-color: var(--color5);
  }
}
.react-datepicker__quarter-text.react-datepicker__quarter--in-range {
  &:hover {
    background-color: var(--color5);
  }
}
.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}
.react-datepicker__year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
  &:hover {
    cursor: pointer;
    .react-datepicker__year-read-view--down-arrow {
      border-top-color: var(--color7);
    }
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: var(--color7);
    }
  }
}
.react-datepicker__month-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
  &:hover {
    cursor: pointer;
    .react-datepicker__year-read-view--down-arrow {
      border-top-color: var(--color7);
    }
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: var(--color7);
    }
  }
}
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
  &:hover {
    cursor: pointer;
    .react-datepicker__year-read-view--down-arrow {
      border-top-color: var(--color7);
    }
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: var(--color7);
    }
  }
}
.react-datepicker__year-dropdown {
  background-color: var(--color3);
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid var(--color2);
  &:hover {
    cursor: pointer;
  }
}
.react-datepicker__month-dropdown {
  background-color: var(--color3);
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid var(--color2);
  &:hover {
    cursor: pointer;
  }
}
.react-datepicker__month-year-dropdown {
  background-color: var(--color3);
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid var(--color2);
  &:hover {
    cursor: pointer;
  }
}
.react-datepicker__year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}
.react-datepicker__month-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}
.react-datepicker__year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  &:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  &:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  &:hover {
    background-color: var(--color1);
    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: var(--color7);
    }
    .react-datepicker__navigation--years-previous {
      border-top-color: var(--color7);
    }
  }
}
.react-datepicker__month-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  &:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  &:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  &:hover {
    background-color: var(--color1);
    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: var(--color7);
    }
    .react-datepicker__navigation--years-previous {
      border-top-color: var(--color7);
    }
  }
}
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  &:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  &:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  &:hover {
    background-color: var(--color1);
    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: var(--color7);
    }
    .react-datepicker__navigation--years-previous {
      border-top-color: var(--color7);
    }
  }
}
.react-datepicker__year-option--selected {
  position: absolute;
  left: 15px;
}
.react-datepicker__month-option--selected {
  position: absolute;
  left: 15px;
}
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}
.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  &::after {
    cursor: pointer;
    background-color: var(--color5);
    color: var(--white);
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "×";
  }
}
.react-datepicker__today-button {
  background: var(--color3);
  border-top: 1px solid var(--color2);
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}
.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
  .react-datepicker__day-name {
    width: 3rem;
    line-height: 3rem;
  }
  .react-datepicker__day {
    width: 3rem;
    line-height: 3rem;
  }
  .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
  }
  .react-datepicker__current-month {
    font-size: 1.44rem;
  }
  .react-datepicker-time__header {
    font-size: 1.44rem;
  }
}
@media (max-width: 400px) {
  .react-datepicker__portal {
    .react-datepicker__day-name {
      width: 2rem;
      line-height: 2rem;
    }
    .react-datepicker__day {
      width: 2rem;
      line-height: 2rem;
    }
    .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem;
    }
  }
}
@media (max-height: 550px) {
  .react-datepicker__portal {
    .react-datepicker__day-name {
      width: 2rem;
      line-height: 2rem;
    }
    .react-datepicker__day {
      width: 2rem;
      line-height: 2rem;
    }
    .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem;
    }
  }
}

.reset_btn {
  cursor: pointer;
  margin: 0 0 10px 0;
  background: var(--black);
  color: var(--white);
  padding: 5px 10px;
  border-radius: var(--border-radius-4);
  display: flex;
  justify-content: center;
  align-items: center;
  &.disabled {
    cursor: no-drop;
    background: var(--grey30);
  }
}
.cust_date_picker {
  background: var(--white);

  // position: relative;
  // margin: 0 0 10px 0;
  // display: flex;
  // border: 1px solid var(--grey30);
  height: 96px;
  overflow: hidden;
  transition: height 0.3s linear;
  // display: flex;
  border-radius: var(--border-radius-4);
  .calender_flex {
    display: flex;
    border: 1px solid transparent;
    transition: border 0.2s 0.2s linear;
    border-radius: var(--border-radius-4);
    overflow: hidden;
    margin: 10px 0 0 0;
    // box-shadow: 0 0 0 1px var(--grey40);
    --options_width: 0px;
    border: 1px solid var(--grey30);
    .calenderoptions {
      opacity: 0;
      // width: 0;
      transition: opacity 0.2s linear, width 0.2s linear;
    }
    .rdrCalendarWrapper {
      transition: width 0.2s linear;
    }
  }
  &.active {
    height: fit-content;
    height: 402px;
    .calender_flex {
      transition: border 0.2s linear;
      --options_width: 200px;
      .calenderoptions {
        opacity: 1;
      }
    }
  }
  .menu {
    cursor: pointer;
    border: 1px solid var(--black);
    display: inline-block;
    height: 30px;
    width: 35px;
    border-radius: 5px;
    // position: relative;
    // &:after,
    // &:before {
    //   content: "";
    //   position: absolute;
    //   height: 3px;
    //   width: 100%;
    //   background: var(--black);
    //   inset: 0;
    //   margin: auto;
    // }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    transition: all 0.3s linear;
    > div {
      //   position: absolute;
      height: 2px;
      width: 70%;
      background: var(--black);
      //   inset: 0;
      //   margin: auto;
      transition: all 0.3s linear;
      &:nth-child(2) {
        transform-origin: left;
      }
    }
    &.active {
      // border: 1px solid var(--grey40);
      > div {
        // background: var(--grey40);
        &:first-child {
          transform: translateY(7px) rotate(45deg);
        }
        &:nth-child(2) {
          transform: scale(0);
        }
        &:last-child {
          transform: translateY(-7px) rotate(-45deg);
        }
      }
    }
  }
  .rdrCalendarWrapper {
    width: calc(100% - var(--options_width));
    .rdrMonth {
      width: 100%;
    }
    .rdrDateDisplayWrapper {
      pointer-events: none;
      .rdrDateDisplayItem {
        border: 0;
      }
    }
  }
  .calenderoptions {
    background: var(--white);

    // position: absolute;
    // top: 0;
    // left: 45px;
    // border-radius: 5px;
    overflow: hidden;
    z-index: 100;
    // height: 0px;
    transition: height 0.3s linear;
    width: var(--options_width);
    // height: 280px;
    // display: flex;
    // flex-wrap: wrap;
    // gap: 10px;
    // column-gap: 10px;
    // &.active {
    // box-shadow: inset 0 0 0 0px var(--grey40);
    border-right: 1px solid var(--grey20);
    // }
    // margin: 10px 0 10px 0;
    > div {
      font-size: 15px;
      // width: calc((100% - 30px) / 4);
      height: fit-content;
      padding: 10px 20px;
      cursor: pointer;
      // transition: all 0.2s linear;
      // border-radius: 20px;
      &:hover {
        background: #eff2f7;
        // color: #3d91ff;
      }
      &:not(:last-child) {
        box-shadow: inset 0px -2px 0 -1px #eff2f7;
        //   border: 1px solid red;
      }
      &.active {
        background: #eff2f7;
        color: #3d91ff;
        font-weight: 500;
      }
    }
  }
}
