.vendortablesidepanel {
  margin: 23px 0 0 0;
  display: flex;
  flex-direction: column;
  height: calc(100% - 23px);
  .vendor_nav {
    display: flex;
    justify-content: space-around;
    font-weight: 500;
    font-size: var(--f14);
    line-height: var(--lh18);
    color: var(--grey40);
    text-transform: uppercase;
    &.active {
      color: var(--black);
    }
  }
  // .vendor_modal_options {
  //   border: 1px solid red;
  // }
  .vendorviewedit {
    margin: auto 0 auto 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      width: 400px;
    }
    .title {
      font-weight: 700;
      font-size: var(--f14);
      line-height: var(--lh18);
      margin-bottom: 7px;
    }
    input {
      margin-bottom: 15px;
      width: 100%;
      border: 1px solid var(--grey30);
      // height: 52px;
      font-weight: 500;
      font-size: var(--f14);
      // line-height: var(--lh19);
      color: var(--grey50);
      border-radius: var(--border-radius-6);
      padding: 8px 12px;
    }
  }
  .vendorcash {
    margin: auto 0 auto 40px;
    .vendorheader {
      display: flex;
      padding: 10px 0 0 0;
      gap: 10px;
      .vendorcash_value {
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--black);
        color: var(--white);
        border-radius: 5px;
        flex-direction: column;
        > div {
          &:first-child {
            font-size: var(--f13);
            line-height: var(--lh17);
            margin: 0 0 10px 0;
          }
          &:last-child {
            font-size: var(--f25);
            line-height: var(--lh17);
            font-weight: 500;
          }
        }
      }
      .vendorcash_inout {
        width: 30%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        > div {
          height: 40px;
          color: var(--white);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          &:first-child {
            background: var(--green);
          }
          &:last-child {
            background: var(--red);
          }
        }
      }
    }
    .vendorcash_menu {
      position: relative;
      .vendorcash_menu_main {
        width: 174px;
        position: absolute;
        top: 0;
        right: calc(100% + 10px);
        background: var(--white);
        border: 1px solid var(--black);
        padding: 14px 13px 21px 23px;
        border-radius: 10px;
        .header {
          width: fit-content;
          margin: 0 0 0 auto;
        }
        .body {
          > div {
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 1.14625px;
            height: 16px;
            &:not(:last-child) {
              margin: 0 0 15px 0;
            }
            &:first-child {
              gap: 10px;
              svg {
                width: 17px;
              }
            }
            &:nth-child(2) {
              gap: 12px;
              svg {
                width: 15px;
              }
            }
            &:last-child {
              gap: 12px;
              svg {
                width: 15px;
              }
            }
          }
        }
      }
    }
    // .vendorcash {
    position: relative;
    // border: 1px solid red;
    .vendorcash_modal {
      position: absolute;
      inset: 0;
      background: var(--modal-bg);
      border-radius: 12px;
      display: flex;
      justify-content: center;
      align-items: center;

      > div {
        background: var(--white);
        .header {
          position: absolute;
          top: 25px;
          right: 31px;
        }
        .body {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          height: 100%;
          > div {
            width: 50%;
            display: flex;
            flex-direction: column;
            height: 100%;
            .name_view {
              font-size: var(--f25);
              line-height: var(--lh33);
            }
            .amount_view {
              font-size: var(--f25);
              line-height: var(--lh33);
              margin: 0 0 6px 0;
              text-align: center;
              white-space: nowrap;
              text-align: right;
              display: flex;
              justify-content: flex-end;
              > div {
              }
            }

            &.left_body {
              width: 191px;
            }
            &.right_body {
              width: 133px;
              padding: 60px 0 0 0;
              display: flex;
              flex-direction: column;
              height: 100%;
              .cashinout_btn {
                height: 31px;
                margin: auto 0 0 10px !important;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .amount {
                // width: fit-content;
                height: 31px;
                width: 100%;
                margin: 0 0 3px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                // color: var(--red) !important;
                border: 1px solid var(--grey30);
                border-radius: var(--border-radius-4);
                font-size: var(--f14);
                line-height: var(--lh18);
                padding: 0 10px 0 10px;
                &.amount_red {
                  color: var(--red) !important;
                }
                &.amount_green {
                  color: var(--green) !important;
                }
                input {
                  border: 0;
                  // color: var(--red) !important;
                  &::placeholder {
                    // color: var(--red) !important;
                  }
                }
                label {
                  //   display: flex;
                  //   justify-content: center;
                  //   align-items: center;
                  height: 100%;
                  width: 100%;
                }
              }
              input {
                width: 100%;
                text-align: center;
                //     &:first-child {
                //       color: var(--red) !important;
                //       &::placeholder {
                //         color: var(--red) !important;
                //       }
                //     }
              }
            }
          }
          input,
          textarea {
            // width: 100%;
            border: 1px solid var(--grey30);
            padding: 0;
            margin: 0;
            height: 31px;
            border-radius: var(--border-radius-4);
            padding: 0 10px 0 10px;
            font-size: var(--f14);
            line-height: var(--lh18);
            font-weight: 500;
            &::placeholder {
              font-weight: 500;
              color: var(--black);
            }
          }
          textarea {
            resize: none;
            height: fit-content;
            padding: 5px 10px 5px 10px;
            text-align: center;
          }
          .current_date_time {
            margin: 11px 0 0 0;
            font-size: var(--f16);
            line-height: var(--lh21);
          }
          .receipt {
            background: var(--white);
            z-index: 1;
            height: 31px;
            width: 100%;
            border: 1px solid var(--grey30);
            border-radius: var(--border-radius-4);
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 55px 0 0 0;
            height: 447px;
            width: 393px;
            border: 1px solid var(--black);
            border-radius: var(--border-radius-12);
            padding: 40px 31px 34px 38px;
            position: relative;
            font-weight: 500;
            input {
              display: none;
            }
            svg {
              margin: 0 8px 0 0;
            }
          }
        }
      }
    }
    // }
  }
  > div:nth-child(2) {
    flex-grow: 1;
  }
}
