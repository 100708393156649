.addordertags {
  margin-top: 29px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 168px);
  .tag_order_id {
    margin-bottom: 42px;
    width: calc(50% - 10px);
  }
  .tag_title {
    font-size: var(--f20);
    line-height: var(--lh26);
    font-weight: 500;
    margin-bottom: 12px;
  }
  input,
  .tag_cust_select {
    width: 100%;
    height: 48px;
    border-radius: var(--border-radius-6);
    border: 1px solid var(--grey30);
    padding-inline: 17px;
    font-size: var(--f16);
    line-height: var(--lh21);
    font-weight: 500;
  }
  .tag_cust_select {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // background: url("../../../img/icons/arrow_down_icon.svg");
    // background-repeat: no-repeat;
    // background-position: calc(100% - 17px);
    // background-size: 24px;
    // image-rendering: crisp-ed;
    position: relative;
    .tag_id_item_dropdown {
      z-index: 100;
      position: absolute;
      top: 100%;
      left: 0px;
      width: 100%;
      // background: var(--grey90);
      color: var(--white);
      border-radius: var(--border-radius-6);
      overflow: hidden;
      height: 0px;
      transition: height 0.3s linear;
      border: 1px solid var(--grey50);
      &[active="false"] {
        border: 1px solid transparent;
      }
      > div {
        height: 55px;
        padding: 10px 17px;
        transition: all 0.3s linear;
        cursor: pointer;
        background: var(--grey90);
        // opacity: 0.6;
        &:not(:last-child) {
          border-bottom: 1px solid var(--grey50);
        }
        &:hover {
          background: var(--black);
          // opacity: 1;
        }
      }
    }
  }
  .tag_id_body {
    width: calc(100% - 27px);
    column-gap: 20px;
    display: flex;
    position: relative;
    > .tag_delete_btn {
      cursor: pointer;
      width: fit-content;
      position: absolute;
      left: calc(100% + 10px);
      //   top: 0;
      //   bottom: 0;
      //   margin: auto;
      height: fit-content;
      width: fit-content;
      //   transform: translateY(100%);
      &.tag_print_btn {
        top: 3px;
        left: calc(50% - 30px);
        // transform: translateY(-100%);
      }
    }
    .tag_id_item_part3 {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      > div {
        &:last-child {
          width: 100%;
        }
        &:not(:last-child) {
          // width: 50%;
          font-size: var(--f10);
          line-height: var(--lh12);
          color: var(--grey40);
        }
        &:first-child {
          margin-right: 5px;
        }
      }
    }
    .tag_id_item_part2 {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      > div {
        &:last-child {
          width: 100%;
        }
        &:not(:last-child) {
          // width: 50%;
          font-size: var(--f10);
          line-height: var(--lh12);
          color: var(--grey40);
        }
        &:first-child {
          margin-right: 5px;
        }
      }
    }
    > div {
      width: 10%;
    }
    position: relative;
    margin-bottom: 22px;
    .tag_id_add_img {
      position: absolute;
      right: -37px;
      top: 0;
      width: fit-content;
      img {
        width: 30px;
      }
      // margin-block: 22px;
    }
  }

  .create_tag_ids {
    overflow-y: scroll;
    height: calc(100vh - 100px);
    margin-bottom: 10px;
    margin-right: -27px;
  }
  .tag_id_body {
    margin-top: auto;
    // &:not(:first-child) {
    //     margin-top: 19px;
    // }
  }

  .tags_btn_group {
    margin-top: auto;
    display: flex;
    column-gap: 20px;
    > div {
      cursor: pointer;
      width: 50%;
    }
  }
}
