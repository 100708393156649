.cash {
  .cash_body {
    // padding: 0 110px 0 0;
    .cash_body_heading {
      height: 182px;
      display: flex;
      margin: 0 0 30px 0;
      > div {
        cursor: pointer;
      }
      .in_cash_register {
        width: 509px;
        background-color: var(--black);
        color: var(--white);
        border-radius: var(--border-radius-12);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > div {
          &:first-child {
            font-size: var(--f13);
            line-height: var(--lh17);
            margin: 0 0 4px 0;
          }
          &:last-child {
            font-size: var(--f50);
            line-height: var(--lh65);
          }
        }
      }
      .cash_action_btn {
        width: calc(100% - 509px - 22px);
        margin: 0 0 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > div {
          height: calc(50% - 6px);
          border-radius: var(--border-radius-12);
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--white);
          font-size: var(--f24);
          line-height: var(--lh31);
          &:first-child {
            background: var(--green);
          }
          &:last-child {
            background: var(--red);
          }
        }
      }
    }
  }
}
