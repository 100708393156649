.adddiscountmodal {
  z-index: 100;
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--modal-bg);

  > div {
    width: 334px;
    height: 478px;
    padding: 41px 26px 21px 38px;
    background: var(--white);
    position: relative;
    border-radius: var(--border-radius-12);
    display: flex;
    flex-direction: column;
    .header {
      width: fit-content;
      position: absolute;
      top: 25px;
      right: 22px;
      //   top: 0;
      //   right: 0;
      //   left: 0;
      //   bottom: 0;
    }
    .body {
      display: flex;
      flex-direction: column;
      gap: 15px;
      .title {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: var(--f16);
        line-height: var(--lh21);
        letter-spacing: 0.9975px;
        margin: 0 0 6px 0;
      }
      .cust_inp_parent {
        position: relative;
      }
      .cust_inp {
        width: 191px;
        height: 31px;
        // width: 191px;
        border: 1px solid var(--black);
        border-radius: var(--border-radius-4);
        overflow: hidden;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 500;
        font-size: var(--f14);
        line-height: var(--lh18);
        letter-spacing: 0.9975px;
        > input {
          width: 100%;
          height: 100%;
          border: 0;
          padding: 0 10px;
          font-weight: 500;
          font-size: var(--f14);
          line-height: var(--lh18);
          &::placeholder {
            font-weight: 500;
            font-size: var(--f14);
            line-height: var(--lh18);
            color: var(--black);
          }
        }
        &.input_switcher {
          .input_switcher_main {
            position: relative;
            display: flex;
            align-items: center;
            height: 100%;
            width: calc(100% + 25px);
            transition: transform 0.3s linear;
            .inp_icon {
              flex: 0 0 25px;
              height: 31px;
              line-height: 31px;
              //   width: 20px;
              text-align: center;
            }
            input {
              //   width: 164px;
              width: calc(100% - 25px);
              height: 100%;
              border: 0;
              transition: padding 0.3s linear;
              //   padding: 0;
            }
          }
        }
      }
      .input_switch {
        cursor: pointer;
        position: absolute;
        bottom: 8px;
        right: 40px;
        // margin: auto 0;
        // width: 28px;
        // height: 16px;
        // background: #737373;
        // border-radius: 8px;
        // .input_switch_blob {
        //   height: 12px;
        //   width: 12px;
        //   background: var(--white);
        //   border-radius: 50%;
        //   margin: 2px;
        //   transition: transform 0.3s linear;
        //   transform: translateX(12px);
        //   &.active {
        //     transform: translateX(0px);
        //   }
        // }
      }
    }
    .footer {
      margin: auto 0 0 auto;
      .save_btn {
        width: 120px;
        height: 31px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--white);
        border-radius: var(--border-radius-4);
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 500;
        font-size: var(--f12);
        line-height: var(--f16);
        background: var(--grey40);
        cursor: no-drop;
        &.active {
          cursor: pointer;
          background: var(--black);
        }
      }
    }
  }
  .discount_dropdown {
    width: 191px;
    font-size: var(--f14);
    line-height: var(--lh18);
    font-weight: 500;
    position: relative;
    border: 1px solid var(--black);
    border-radius: var(--border-radius-4);
    // &:hover {
    //   z-index: 100;
    // }
    .discount_dropdown_default {
      cursor: pointer;

      .default_items {
        width: calc(100% - 30px);
        padding: 5px 10px;
        &:not(:first-child) {
          border-top: 1px solid var(--grey30);
        }
      }
      .discount_dropdown_arrow {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10px;
        margin: auto 0;
        height: 10px;
        width: 10px;
        // background: var(--black);
        transition: box-shadow 0.3s linear;
        // border: 1px solid red;
        // background-image: linear-gradient(transparent 8px, #000 2px),
        //   linear-gradient(90deg, transparent 8px, #000 2px);
        // background-size: 100% 100%, 100%8100%;
        // background-position: 0 -8px, -8px 0;
        // background-position-x: 5px;
        box-shadow: inset -2px -2px 0 0 var(--black);
        transform: translateY(-2px) rotate(45deg);

        &[active="true"] {
          box-shadow: inset 2px 2px 0 0 var(--black);
          transform: translateY(2px) rotate(45deg);
        }
      }
    }
    .discount_dropdown_main {
      background: var(--white);
      position: absolute;
      width: 100%;
      top: calc(100% + 5px);
      left: 0;
      box-shadow: inset 0 0 0 1px var(--grey30);
      border-radius: var(--border-radius-6);
      overflow: hidden;
      transition: height 0.3s linear;
      z-index: 100;
      .discount_dropdown_items {
        cursor: pointer;

        padding: 5px 10px;
        transition: background 0.3s linear;
        display: flex;
        height: 30px;
        .txt {
          width: calc(100% - 20px);
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .tick {
          width: 20px;
          // border: 1px solid red;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            --svg_current_color: var(--green) !important;
          }
        }
        &:hover {
          background: var(--grey30);
        }
        &[active="true"] {
          font-weight: 700;
          letter-spacing: 2px;
          text-transform: uppercase;
          background: var(--black);
          color: var(--white);
        }
      }
    }
    .discount_dropdown_closer {
      position: fixed;
      inset: 0;
      background: transparent;
    }
  }
  .dropdown_group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
