.orders {
  padding-right: 120px !important;
  @media screen and (max-width: 500px) {
    padding-right: 45px !important;
  }
  .order_table_body {
    > .search_and_filter_bar {
      display: flex;
      // height: 40px;
      .filter_img_div {
        display: flex;
        justify-content: center;
        align-items: center;

        height: auto;
      }
      .search_input {
        border: 1.12791px solid var(--grey30);
        border-radius: var(--border-radius-6);
        overflow: hidden;
        width: 100%;
        display: flex;
        align-items: center;
        img {
          margin-left: 14.16px;
          margin-right: 23.3px;
        }
        > input {
          height: 38px;
          width: 100%;
          border: 0;

          padding: 0px;
          &::placeholder {
            color: var(--grey40);
          }
        }
        margin-right: 11px;
      }
    }
    .filter_btn_group {
      display: flex;
      .filter_card {
        margin-top: 24px;
        background: var(--black);
        // width: 138px;
        height: 35px;
        border-radius: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-inline: 20px 13px;
        color: var(--white);
        font-weight: 500;
        font-size: var(--f13);
        line-height: var(--lh17);
        margin-right: 8px;
        svg {
          margin-left: 9px;
        }
      }
    }
    .order_table {
      margin-top: 23.12px;
    }
  }
}
