.discount_body {
  .discount_search_and_addbtn {
    display: flex;
    align-items: center;
    column-gap: 20px;
    height: 40px;
    .searchandfilterbar {
      width: calc(100% - 200px - 20px);
    }
    .add_discount {
      cursor: pointer;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      width: 200px;
      background: var(--black);
      color: var(--white);
      border-radius: var(--border-radius-6);
      font-size: var(--f24);
      line-height: var(--31);
      //   svg {
      //     height: 25px;
      //     width: 25px;
      //   }
      .add {
        height: 11px;
        width: 11px;
        position: relative;
        // border: 1px solid red;
        &::after,
        &::before {
          position: absolute;
          content: "";
          inset: 0;
          //   width: 100%;
          width: 2px;
          height: 100%;
          background: #fff;
          margin: 0;
          border-radius: 5px;
        }
        &::before {
          transform: rotate(90deg);
        }
      }
    }
  }
}
