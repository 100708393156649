.add_faq {
  position: fixed;
  inset: 0;
  background: var(--modal-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  > div {
    width: fit-content;
    height: fit-content;
    background: var(--white);
    border: 1px solid var(--black);
    border-radius: 12px;
    padding: 32px 34px 24px 38px;
    position: relative;
    overflow: hidden;
    .header {
      > svg {
        position: absolute;
        right: 24px;
        top: 25px;
        z-index: 10;
      }
    }
    .body {
      width: 800px;
      padding: 0 8px 0 0;
      font-size: 16px;
      line-height: 17px;
      .title {
        font-weight: 700;
        font-size: var(--f30);
        line-height: var(--lh39);
      }
      .body_question_title {
        margin: 26px 0 6px 0;
        font-weight: 700;
        // font-size: var(--f16);
        // line-height: var(--lh21);
      }
      .cust_inp {
        border: 1px solid var(--black);
        border-radius: var(--border-radius-4);
        > input {
          border-radius: var(--border-radius-4);
          border: 0;
          width: 100%;
          padding: 16px 19px;
        }
      }
      //
      input {
        width: 100%;
        width: 0;
        padding: 0;
        margin: 0;
        border: 0;
      }
      label {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .show_cursor {
        position: relative;
        width: fit-content;
        &::after {
          position: absolute;
          content: "";
          height: 100%;
          width: 1px;
          background: var(--black);
          top: 0;
          right: 0;
          opacity: 1;
          animation: infinite 0.8s flicker;
          @keyframes flicker {
            0%,
            100% {
              opacity: 1;
            }
            50% {
              opacity: 0;
            }
          }
        }
        &[is_default="true"]::after {
          right: unset;
          left: 0;
        }
      }
      h1,
      h2,
      h3 {
        margin: 0;
        line-height: 1;
      }
      ul,
      ol {
        margin: 10px 0 10px 0;
      }
      .ques {
        display: flex;
        margin: 26px 0 20px 0;
        // font-size: var(--f18);
        // line-height: var(--lh22);
        // font-weight: 700;
        overflow: visible;
        .ques_q {
          width: 15px;
          margin: 60px 10px 0 0;
        }
      }
      .ans {
        display: flex;
        margin: 26px 0 20px 0;
        font-weight: 400;
        // font-size: var(--f14);
        // line-height: var(--lh18);
        .ans_a {
          width: 15px;
          margin: 60px 10px 0 0;
        }
      }
      .link_btn_container {
        // border: 1px solid red;
        .link_btn_items {
          margin: 0 0 10px 0;
          display: flex;
          // justify-content: space-between;
          align-items: center;
          column-gap: 5px;
          .link_btn {
            margin: 0 0 0 25px;
            // background: var(--black);
            border: 1px solid var(--black);
            border-radius: 5px;
            padding: 10px 20px;
            font-weight: 700;
            display: flex;
            column-gap: 10px;
            .link_img {
              height: 20px;
              width: 20px;
            }
            a {
              color: var(--black);
              text-decoration: none;
            }
          }
          .link_btn_delete {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            cursor: pointer;
            // &:after,
            // &:before {
            //   content: "";
            //   position: absolute;
            //   inset: 0;
            //   margin: auto;
            //   height: 15px;
            //   width: 3px;
            //   transform: rotate(45deg);
            //   background: var(--black);
            // }
            // &:before {
            //   transform: rotate(-45deg);
            // }
            svg {
              path {
                transition: all 0.1s linear;
                // fill: var(--red);
              }
            }
            &:hover {
              svg {
                path {
                  fill: var(--red);
                }
              }
            }
          }
        }
        .add_link_btn {
          display: flex;
          height: 35px;
          column-gap: 10px;
          margin: 0 0 10px 0;
          > div {
            // background: var(--black);
            // border: 1px solid green;
            // width: 100%;
            width: calc(50% - 100px);
            display: flex;

            input {
              flex-grow: 1;
              width: 100%;
              border-radius: 5px;
              padding: 10px;
              border: 1px solid var(--grey40);
            }
          }
          .add_link {
            cursor: pointer;
            width: 100px;
            background: var(--black);
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0.2;
            transition: opacity 0.3s linear;
            > div {
              height: 20px;
              width: 20px;
              background: var(--white);
              clip-path: polygon(
                40% 0%,
                60% 0%,
                60% 40%,
                100% 40%,
                100% 60%,
                60% 60%,
                60% 100%,
                40% 100%,
                40% 60%,
                0% 60%,
                0% 40%,
                40% 40%
              );
            }
          }
          .icon_selector {
            // width: fit-content;
            width: 50px;
            height: 50px;

            // border: 1px solid red;
          }
        }
      }
    }
    .footer {
      display: flex;
      column-gap: 10px;
      > div {
        flex-grow: 1;
      }
    }
    // ?preview
    // overflow: hidden;
    transition: all 0.3s linear;
  }
}
