.four_cards {
  display: flex;
  margin-bottom: 43px;
  > .card_body {
    width: 25%;
    text-align: center;
    height: 92px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius-12);
    border: 1px solid var(--black);
    margin-inline: 10px;
    .card_title {
      font-size: var(--f12);
      line-height: var(--f16);
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 3px;
    }
    .card_value {
      font-size: var(--f30);
      line-height: var(--lh39);
      font-weight: 500;
      // max-width: 100%;
      overflow: hidden;
      // max-width: calc(100% - 20px);
      // .avg_order_card {
      //   height: 100%;
      // }
      .animate_card_to_infinity {
        > div {
          width: fit-content;
          // animation: animate_card_to_infinity 7s infinite linear;
          div {
            margin: 0 5px;
          }
          @keyframes animate_card_to_infinity {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-50%);
            }
          }
        }
        // padding: 0 10px;
        // border: 1px solid red;
      }
    }
    &.avg_order_card {
      // border: 1px solid red;
      flex-direction: row;
      overflow: hidden;
      .card_left {
        width: 65%;
      }
      .card_num {
        font-size: var(--f30);
        line-height: var(--lh39);

        text-transform: uppercase;
        font-weight: 500;
      }
      .card_value {
        height: 100%;
        width: 35%;
        font-size: var(--f12);
        line-height: var(--f16);
        border-left: 1px solid var(--black);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > div {
          height: 33.33%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          &.active {
            background: var(--black);
            // color: var(--white);
          }
          &:nth-child(2) {
            border-top: 1px solid var(--black);
            border-bottom: 1px solid var(--black);
          }
        }
      }
    }
    &.last_card {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      background-color: var(--black);
      color: var(--white);
      border-radius: var(--border-radius-12);
      font-size: var(--f24);
      line-height: var(--lh31);
      font-weight: 500;
      img {
        margin-right: 12px;
      }
    }

    &:first-child {
      margin-right: 10px;
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
      margin-left: 10px;
    }
  }
}
