.clients {
  padding-top: 44px;
  .four_cards {
    margin-bottom: 30px;
  }
  .client_filter_group {
    display: flex;
    column-gap: 8px;
    .filter_cards {
      background: var(--black);
      color: var(--white);
      height: 35px;
      display: inline-block;
      width: fit-content;
      border-radius: 20px;
      padding: 9px 13px 9px 20px;
      font-weight: 500;
      font-size: 13px;
      line-height: 17px;
      column-gap: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 18px;
    }
  }
  .clients_table {
    .client_table_main {
      --profile-pic-width: 27px;
      --profile-pic-margin-right: 13px;
      --menu-width: 20px;
      --computable-width: calc(
        100% - (var(--profile-pic-width) + var(--profile-pic-margin-right)) -
          var(--menu-width)
      );
      .child_row {
        width: var(--computable-width);
      }
      .column1 {
        width: var(--profile-pic-width);
        margin-right: var(--profile-pic-margin-right);
      }
      .column2 {
        width: 18%;
      }
      .column3 {
        width: 21%;
      }
      .column4 {
        width: calc(19.7% + 4.6%);
      }
      .column5 {
        text-align: center !important;
        width: 20%;
      }
      .column6 {
        text-align: center !important;
        width: 16%;
      }
      .column7 {
        width: var(--menu-width);
      }
      .table_heading {
        margin-top: 23.12px;
        margin-bottom: 12px;
        text-align: center !important;
        .column1,
        .column7 {
          color: transparent;
        }
        .column1,
        .column2,
        .column3,
        .column4 {
          text-align: left;
        }
        > div {
          height: 17px;
        }
      }
      .row {
        margin-bottom: 23px !important;
      }
      .child_row {
        height: 17px;
        > div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 5px;
        }
      }
      .table_body {
        // height: ;
        overflow-y: scroll;
        height: calc(100vh - 350px);
        // border: 1px solid red;
        .column1 {
          height: 27px;
        }
        .column7 {
          > div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          // height: 16px;
        }
      }
      .client_table_menu_main {
        position: relative;
        .clienttablemenu {
          position: absolute;
          background: var(--white);
          top: 100%;
          right: calc(100% + 5px);
          border-radius: var(--border-radius-10);
          border: 1px solid var(--black);
          width: 174px;
          height: 0px;
          overflow: hidden;
          transition: 0.3s height linear;
          opacity: 0;
          z-index: -1;
          padding: 14px 13.34px 22px 23.15px;
          .header {
            height: 10px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            img {
              height: 10px;
              width: 10px;
            }
          }
          .body {
            margin-top: 9.34px;
            > * {
              user-select: auto;
            }
            > div {
              display: flex;
              font-style: normal;
              font-weight: 500;
              font-size: var(--f13);
              line-height: var(--lh17);
              height: 17px;
              cursor: pointer;
              &:not(:last-child) {
                margin-bottom: 16px;
              }
              > .body_img {
                width: 15px;
                margin-right: 19.03px;
                img {
                  width: 100%;
                }
              }
              > .body_txt {
              }
            }
          }
        }
        &[show="true"] {
          .clienttablemenu {
            z-index: 10;
            opacity: 1;
            // height: 236px;
            // height: 205px;
            // height: fit-content;
            height: 320px;
          }
        }
      }
    }
    .client_details {
      margin-top: 19px;
      display: flex;
      .left_div {
        width: 220px;
        border-right: 1px solid var(--black);
        padding-right: 20px;
        overflow-y: scroll;
        height: calc(100vh - 265px);
        // margin-right: -100px;
        padding-block: 5px;
        > .client_view_cards {
          margin-top: 15px;
          border: 1px solid var(--grey30);
          border-radius: var(--border-radius-6);
          padding: 7px 0px 6px 12px;
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: var(--f14);
          line-height: var(--lh18);
          transition: border 0.2s linear;
          // border: 1px solid transparent;
          // width: 200px;
          height: 36px;
          img {
            margin-right: 17px;
            height: 23px;
          }
          &.client_mini_menu_active {
            border-color: var(--black);
          }
        }
      }
      .right_div {
        width: calc(100% - 220px);
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 23px;
        height: calc(100vh - 265px);

        .right_div_menu {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          font-weight: 500;
          font-size: var(--f14);
          line-height: var(--lh18);
          color: var(--grey40);
          height: 24px;
          margin-bottom: 33px;
          > div {
            // comment mergin when analytics is added
            margin: auto;
            cursor: pointer;
            &:not(:last-child) {
              margin-inline: auto;
            }

            &:last-child {
              margin-left: auto;
            }
          }
          .menu_active {
            color: var(--black);
          }
        }
        &.view_details,
        &.edit_details {
          .right1 {
            height: calc(100% - 57px);
            width: calc(100% - 310px - 100px);
            // flex-grow: 1;
            margin-left: 40px;
            // overflow: hidden;
            margin-right: 60px;
            display: flex;
            align-items: flex-start;
            > div {
              width: 100% !important;
            }
            .personal_details {
              display: flex;
              .img {
                width: 73px;
                height: 73px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 18px;
                img {
                  height: auto;
                  width: 100%;
                }
              }
              .details {
                div {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  font-weight: 500;
                  font-size: var(--f14);
                  line-height: var(--lh21);

                  &:first-child {
                    font-size: var(--f20);
                    line-height: var(--lh26);
                  }
                }
              }
            }
          }
          .right2 {
            height: calc(100% - 57px);

            width: 310px;
            display: flex;
            flex-direction: column;
            .right2_cards {
              &:first-child {
                // margin-top: 33px;
              }
              &:nth-child(2) {
                margin-block: auto;
              }

              .card_title {
                font-weight: 700;
                font-size: var(--f14);
                line-height: var(--lh18);
                margin-bottom: 7px;
              }
              .card_details {
                border: 1px solid var(--grey30);
                height: 52px;
                font-weight: 500;
                font-size: var(--f14);
                line-height: var(--lh19);
                color: var(--grey50);
                border-radius: var(--border-radius-6);
                padding: 8px 12px;
                &.card_notes_details {
                  height: 95px;
                }
              }
              .card_details {
                position: relative;
                .prev_address,
                .next_address {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  width: 5px;
                  height: fit-content;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  filter: invert(100%);
                  > img {
                    cursor: pointer;
                    width: 100%;
                  }
                }
                .prev_address {
                  left: -15px;
                }
                .next_address {
                  right: -15px;
                }
              }
            }
          }
          .right2_btn_group {
            margin-top: 21px;
            display: flex;
            column-gap: 12px;
            > div {
              width: 50%;
            }
          }
        }
        &.view_details {
          .right1 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            // align-items: center;
            .personal_details {
              margin-top: calc(33px - 33px);
              img {
                margin-right: auto;
              }
              .details {
                // margin-left: 18px;
                width: calc(100% - 73px - 18px);
              }
            }
            .client_credit {
              display: flex;
              // display: none;
              border: 1px solid var(--black);
              width: 220px;
              height: 82px;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              border-radius: var(--border-radius-10);
              margin-top: 43px;
              margin-bottom: 24px;
              div {
                &:first-child {
                  font-weight: 500;
                  font-size: var(--f12);
                  line-height: var(--lh16);
                }
                &:last-child {
                  font-weight: 500;
                  font-size: var(--f25);
                  line-height: var(--lh33);
                  color: var(--grey40);
                }
              }
            }
            .open_amount {
              display: flex;
              // display: none;
              margin: auto 0;
              // margin-bottom: 23px;

              width: 229px;
              height: 163px;
              background: var(--black);
              color: var(--white);
              justify-content: center;
              align-items: center;
              flex-direction: column;
              border-radius: var(--border-radius-12);
              > div:first-child {
                font-weight: 500;
                font-size: var(--f12);
                line-height: var(--lh16);
              }
              .amount {
                margin-bottom: 17px;
                font-weight: 500;
                font-size: var(--f35);
                line-height: var(--lh46);
              }
              .btn {
                width: 144px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 500;
                font-size: var(--f16);
                line-height: var(--lh21);
              }
            }
          }
        }
        &.edit_details {
          .right1 {
            .personal_details {
              width: 230px;
              flex-direction: column;
              // margin-top: 8px;

              .profile_pic {
                text-align: center;
                height: 105px;
              }
              .details {
                margin-bottom: 18px;
                > div:not(:last-child) {
                  margin-bottom: 20px;
                }
                input {
                  width: 100%;
                  height: 31px;
                  border: 1px solid var(--grey30);
                  padding: 6px 10px;
                  border-radius: var(--border-radius-4);
                  font-weight: 500;
                  font-size: var(--f14);
                  line-height: var(--lh18);
                  color: var(--black);
                }
                .title {
                  font-weight: 700;
                  font-size: var(--f14) !important;
                  line-height: var(--lh18) !important;
                  margin-bottom: 7px;
                }
              }
              .client_credit {
                border: 1px solid var(--black);
                border-radius: var(--border-radius-10);
                height: 68px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                // visibility: hidden;

                > div {
                  &:first-child {
                    font-weight: 500;
                    font-size: var(--f12);
                    line-height: var(--lh16);
                  }
                  &:last-child {
                    font-weight: 500;
                    font-size: var(--f25);
                    line-height: var(--lh33);
                  }
                }
              }
            }
          }
          .right2_cards {
            .card_details {
              color: var(--black) !important;
              position: relative;
              .prev_address,
              .next_address {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 5px;
                height: fit-content;
                display: flex;
                justify-content: center;
                align-items: center;
                filter: invert(100%);
                > img {
                  cursor: pointer;
                  width: 100%;
                }
              }
              .prev_address {
                left: -15px;
              }
              .next_address {
                right: -15px;
              }
            }
            .card_title {
              display: flex;
              img {
                cursor: pointer;
                margin-left: auto;
                height: 20px;
              }
            }
          }
          .editclient_add_address_modal {
            position: fixed;
            inset: 0;
            background: var(--modal-bg);
            display: flex;
            justify-content: center;
            align-items: center;
            > div {
              width: 334px;
              height: 268px;
              background: var(--white);
              border: 1px solid var(--black);
              border-radius: var(--border-radius-12);
              padding: 27px 22px 25px 38px;
              display: flex;
              flex-direction: column;
              .heading {
                display: flex;
                svg {
                  cursor: pointer;
                  margin-left: auto;
                }
              }
              .body {
                display: flex;
                flex-direction: column;
                > .title {
                  font-weight: 700;
                  font-size: var(--f16);
                  line-height: var(--lh21);
                }
                > input {
                  margin-top: 6px;
                  height: 31px;
                  border: 1px solid var(--grey30);
                  border-radius: var(--border-radius-4);
                  font-weight: 500;
                  font-size: var(--f14);
                  line-height: var(--lh18);
                  padding: 7px 10px;
                  color: var(--black);
                  &::placeholder {
                    color: var(--black);
                  }
                }
              }
              .footer {
                margin-top: auto;
                margin-left: auto;
                .btn {
                  height: 31px;
                  width: 120px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }
        }
        &.orderhistory_details {
          justify-content: flex-start;
          flex-direction: column;
          // flex-wrap: nowrap;
          > div:not(.right_div_menu) {
            padding-left: 48px;
          }
          .client_order_history_table {
            flex-grow: 1;
            // overflow: scroll;
            width: 100%;
            margin-top: 23px;
            height: calc(100% - 120px);
            --action-col-width: 26px;
            .column1 {
              width: calc((100% - var(--action-col-width)) * 0.29);
            }
            .column2 {
              width: calc((100% - var(--action-col-width)) * 0.24);
            }
            .column3 {
              width: calc((100% - var(--action-col-width)) * 0.25);
            }
            .column4 {
              width: calc((100% - var(--action-col-width)) * 0.21);
            }
            .column5 {
              margin-left: auto;
              width: var(--action-col-width);
              display: flex;
              justify-content: flex-end;
              align-items: center;
            }
            .table_heading {
              .column5 {
                color: transparent;
              }
            }
            .table_body {
              height: calc(100% - 34px);
              overflow: scroll;
              .row:not(:last-child) {
                padding: 0 0 30px 0;
              }
              > * {
                color: var(--black);
              }
            }
          }
        }
        &.analytics_details {
          .analytics_body {
            // display: flex;
            height: calc(100% - 57px);
            width: 100%;
            .right1 {
              // order: 1;
              margin-left: 48px;
              margin-right: 43px;
              // width: 191px;
              transform: translate(0);
              position: relative;
              top: 0;
              z-index: 100;
              height: 30px;
              .date_cust_dropdown {
                margin-top: 6px;
                border-radius: var(--border-radius-4);
                border: 1px solid var(--grey30);
                // height: 31px;
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-weight: 500;
                font-size: var(--f14);
                line-height: var(--lh18);
                padding: 10px 20px;
                // overflow: hidden;
                // .date_cust_dropdown_arrow {
                //     position: absolute;
                //     right: 10px;
                //     top: 0;
                //     bottom: 0;
                //     margin: auto;
                // }
                label {
                  height: 54px;
                }
                .react-datepicker-wrapper {
                  height: 0px;
                  overflow: hidden;

                  .analytics_datepicker {
                    display: none;
                  }
                }
                .react-datepicker__tab-loop {
                  // position: relative;
                  .react-datepicker-popper {
                    transform: unset !important;
                    inset: 0 !important;
                    top: 75px !important;
                  }
                }
                .date_clear_btn {
                  cursor: pointer;
                  position: absolute;
                  // left: calc(100% + 5px);
                  right: 20px;
                  top: 0;
                  bottom: 0;
                  height: fit-content;
                  margin: auto;
                  // opacity: 0;
                  transition: all 0.3s linear;
                  &.date_clear_btn_active {
                    opacity: 1;
                  }
                }
              }
              .date_clear_btn {
                // visibility: hidden;
                background: var(--black);
                color: var(--white);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: var(--border-radius-4);
                height: 21px;
                margin-bottom: 6px;
              }
            }
            .right2 {
              // background: red;
              // z-index: -1;
              // transform: translate(10px);
              // width: calc(100% - 191px - 48px - 43px);
              height: calc(100% - 72px);
              // cursor: pointer;
            }
          }
          .right2 {
            // background: red;
            border: 1px solid var(--grey30);
            // margin-left: 48px;
            // margin-right: 43px;
            margin: 65px 43px 0 48px;
            padding: 10px;
            display: flex;
            // flex-direction: column;
            // justify-content: center;
            align-items: center;
            gap: 20px;
            .analytics_graph {
              height: 100%;
              width: calc(60% - 10px);
            }
            .cards_group {
              // width: 40%;
              width: calc(40% - 10px);
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              .cards {
                width: 100%;
                border: 1px solid var(--black);
                padding-block: 21px 19px;
                border-radius: var(--border-radius-12);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                &:nth-child(2) {
                  margin-block: 20px;
                }
                .title {
                  font-weight: 500;
                  font-size: var(--f10);
                  line-height: var(--lh13);
                }
                .body {
                  font-weight: 500;
                  font-size: var(--f30);
                  line-height: var(--lh39);
                }
              }
            }
          }
        }
      }
    }
  }
}
