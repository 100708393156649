.addordermodal {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--modal-bg);
    position: fixed;
    inset: 0;
    > div {
        background: var(--white);
        width: 334px;
        height: 182px;
        border: 1px solid var(--black);
        border-radius: var(--border-radius-12);
        padding: 26px 22px 22px 38px;
        display: flex;
        flex-direction: column;
        .header {
            display: flex;
            justify-content: flex-end;
        }
        .body {
            padding-right: 14px;
            margin-top: 6px;
            .title {
                font-weight: 700;
                font-size: var(--f16);
                line-height: var(--lh21);
            }
            .cust_dropdown {
                border-radius: var(--border-radius-4);
                position: relative;
                width: 100%;
                border: 1px solid var(--grey30);
                height: 31px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-inline: 10px 14px;
                font-weight: 500;
                font-size: var(--f14);
                line-height: var(--lh18);
                cursor: pointer;
                .main_dropdown {
                    height: 0px;
                    overflow: hidden;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    background: var(--black);
                    color: var(--white);
                    border-radius: var(--border-radius-4);
                    transition: height 0.3s linear;
                    > div {
                        padding: 5px 10px;
                        &:not(:last-child) {
                            border: 1px solid var(--grey50);
                        }
                    }
                }
                &.cust_dropdown_active {
                    .main_dropdown {
                        height: 60px;
                    }
                }
            }
        }
        .footer {
            margin-top: auto;
            margin-left: auto;
            .btn {
                height: 31px;
                width: 120px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
