:root {
  --btn-padding-block: 13px;
  --border-radius-4: 4px;
  --border-radius-6: 6px;
  --border-radius-8: 8px;
  --border-radius-10: 10px;
  --border-radius-12: 12px;
  --border-radius-20: 20px;
  --border-radius-40: 40px;
}
/* width */
::-webkit-scrollbar {
  position: sticky !important;
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--grey30);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--grey90);
}
[scrolltype="white"] {
  &::-webkit-scrollbar-track {
    background: var(--grey90);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--white);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--white);
  }
}
::selection {
  background: #000;
  color: #fff;
}
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  // user-select: none;
  font-family: "DM Sans", sans-serif;
  letter-spacing: 1px !important;
  margin: 0;
  overflow: hidden;

  * {
    box-sizing: border-box;
  }
  input,
  textarea {
    font-family: "DM Sans", sans-serif;
    letter-spacing: 1px !important;
    &:focus-visible {
      outline: 0;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      -webkit-apperarence: none !important;
      margin: 0 !important;
    }
    &[type="number"] {
      -moz-appearence: textfield !important;
    }
  }
  .btn {
    padding-block: var(--btn-padding-block);
    font-size: var(--f16);
    line-height: var(--lh21);
    border-radius: var(--border-radius-6);
    text-align: center;
    font-weight: 500;
    color: var(--white);
    transition: all 0.4s linear;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &[type="type1"] {
      cursor: default;
      background-color: var(--grey30);
    }
    &[type="type2"] {
      background-color: var(--black);
    }
    &[type="type3"] {
      background-color: var(--green);
    }
    &[type="type4"] {
      background-color: var(--white);
      color: var(--black);
    }
    &[type="type5"] {
      background-color: var(--red);
      color: var(--white);
    }
  }
  *[fcolor="grey50"] {
    color: var(--grey50);
  }
  *[fcolor="red"] {
    color: var(--red);
  }
  *[fcolor="green"] {
    color: var(--green);
  }
  svg {
    cursor: pointer;
    overflow: visible;
  }
  img[src='https://julietteimages.s3.amazonaws.com/profileimages/default.png?1656920271059']
  {
    filter: invert(100%);
    // height: auto;
    // width: 100%;
  }
  [inp_error="true"] {
    border-color: red !important;
  }
  .loading {
    position: fixed;
    inset: 0;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 0 !important;
    .hanger {
      $dashlen: 46;
      stroke-dasharray: $dashlen;
      animation: dash2 5s infinite linear;
      @keyframes dash2 {
        0% {
          stroke-dashoffset: 0;
        }
        100% {
          stroke-dashoffset: 92;
        }
      }
    }
  }

  @media print {
    .print_btn {
      display: none;
    }
  }
}
