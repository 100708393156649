.chargeordermodal {
    position: fixed;
    inset: 0;
    background: var(--modal-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    > div {
        background: var(--white);
        width: 464px;
        height: 447px;
        border: 1px solid var(--black);
        border-radius: var(--border-radius-12);
        padding: 32px 31px 34px 38px;
        display: flex;
        flex-direction: column;
        .chargeordermodal_header {
            // text-align: right;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            font-weight: 700;
            font-size: var(--f30);
            line-height: var(--lh39);
        }
        .chargeordermodal_body {
            display: flex;
            justify-content: center;
            align-items: center;
            // margin-block: auto;
            margin-block: 27px auto;
            .cards {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                column-gap: 14px;
                margin: auto;
                .card_body {
                    cursor: pointer;
                    width: 146px;
                    height: 66px;
                    margin-bottom: 16px;
                    border-radius: var(--border-radius-6);
                    border: 1px solid var(--grey30);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-size: var(--f16);
                    line-height: var(--lh21);
                    font-weight: 500;
                    transition: 0.2s all linear;
                    position: relative;
                    &:not([card_disabled="true"]).card_body_active {
                        border: 1px solid var(--black);
                    }
                    &[card_disabled="true"] {
                        pointer-events: none;
                        border-color: var(--grey20);
                        background-color: var(--grey20);
                        color: var(--grey50);
                    }
                    .cc_card_dropdown {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        right: 0;
                        text-align: left;
                        height: 0px;
                        transition: all 0.3s linear;
                        overflow: hidden;
                        z-index: 10;
                        background: var(--white);
                        border: 1px solid var(--black);
                        border-top: 0;
                        margin-top: 1px;
                        margin-inline: -1px;
                        border-bottom-left-radius: var(--border-radius-6);
                        border-bottom-right-radius: var(--border-radius-6);
                        opacity: 0;
                        &.card_dropdown_active {
                            opacity: 1;
                        }
                        > div {
                            padding-block: 6px;
                            > .cc_card_body {
                                padding-block: 6px;
                                font-weight: 400;
                                font-size: var(--f14);
                                line-height: var(--lh18);
                                padding-left: 20px;
                                height: 31px;
                                &.current_selected_card {
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }
        }
        .chargeordermodal_footer {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .total_due {
                > div {
                    font-size: var(--f16);
                    line-height: var(--lh21);
                    &:first-child {
                        font-weight: 400;
                    }
                    &:last-child {
                        font-weight: 700;
                    }
                }
            }
            .btn {
                width: 120px;
                height: 31px;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 500;
                // padding: 7px 10px 6px 10px;
                font-size: var(--f14);
                line-height: var(--lh18);
            }
        }
    }
}
