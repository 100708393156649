@import "./variable.scss";
:root {
  // --black: #000000;
  --grey90: #363636;
  --grey50: #737373;
  --grey40: #b9b9b9;
  // --grey30: #d8d8d8;
  --grey20: #f2f2f2;
  // --white: #ffffff;
  --green: #67d114;
  --red: #f11c1c;
  --blue: #3387ff;
  // --modal-bg: #3636368f;
  --modal-bg: #00000033;
  // fix colors
}
