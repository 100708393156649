.order_filter_modal {
    .filtermodal {
        padding: 26px 38px 29px 38px;
        border-radius: var(--border-radius-12);
        border: 1px solid var(--black);
        background: var(--white);
        width: 334px;
        height: 372px;
        display: flex;
        flex-direction: column;

        .header {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .body {
            padding-right: 16px;
            .custdatepicker .calender_main {
                left: calc(50% - 9px);
            }
            .mt-15 {
                margin-top: 15px;
            }
            .mb-6 {
                margin-bottom: 6px;
            }
            .mt-6 {
                margin-top: 6px;
            }
        }
        .footer {
            margin-top: auto;
            margin-left: auto;
            .btn {
                height: 31px;
                width: 120px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
