.cust_rte {
  margin: 50px 0 0 0;
  .msg_main {
  }
  .msg_txt {
    outline: 0;
    box-shadow: 0 0 10px var(--modal-bg);
    border-radius: 5px;
    padding: 10px;
  }
}
