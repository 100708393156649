.pricelistaccordian {
    margin-top: 3px;
    height: calc(100vh - 204px);
    overflow-y: scroll;
    .accordian_title {
        font-size: var(--f20);
        line-height: var(--lh26);
        font-weight: 700;
        padding-bottom: 7px;
        border-bottom: 1px solid var(--black);
        // &.pt {
        //     padding-top: 22px;
        // }
        // &.pt {
        //     font-weight: 400 !important;
        // }
    }
    .accordian_mainbody {
        height: 0px;
        transition: all 0.3s linear;
        overflow: hidden;
    }
    .accordian_body {
        // height: 40px;
        height: 0;
        overflow: hidden;
        // display: ;
        flex-wrap: wrap;
        transition: all 0.3s linear;
        position: relative;
        .accordian_body_sub {
            display: flex;
            flex-wrap: wrap;
            column-gap: 5px;
            position: absolute;
            width: 100%;
            padding-top: 26px;
            height: fit-content;

            > div {
                // width: calc((100% / 3) - 11px);
                width: 136px;
                height: 66px;
                cursor: pointer;
                // display: grid;
                // grid-template-rows: 1fr auto;
                break-inside: avoid;
                border: 1px solid var(--grey30);
                border-radius: var(--border-radius-6);
                // width: 33%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                white-space: nowrap;
                margin-bottom: 10px;
                padding-block: 15px 11px;
                padding-inline: 7px;
                text-align: center;
                color: var(--black);
                font-weight: 500;
                > div:first-child {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: var(--f16);
                    line-height: var(--lh21);
                    font-weight: 500;
                }
                > div:last-child {
                    font-size: var(--f14);
                    line-height: var(--lh18);
                }
                &:hover {
                    border-color: var(--black);
                }
                > div {
                    // border: 1px solid red;
                }
            }
        }
    }
    > .accordian_main {
        .accordian_title {
            cursor: pointer;
            margin-top: 26px;
            position: relative;
            // .pt {
            //     position: relative;
            // }
            .arrow {
                width: 16px;
                position: absolute;
                right: 10px;
                top: 0;
                bottom: 0;
                margin: auto;
                transition: 0.3s all linear;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    transition: 0.3s all linear;
                    // width: 100%;
                }
                &.active {
                    img {
                        transform: rotate(180deg);
                    }
                }
            }
            &.pt {
                font-weight: 400;
                .arrow {
                    // padding-top: 22px;
                    // top: 20px !important;
                }
            }
        }
    }
    .other_card_data {
        cursor: pointer;
        margin-top: 24px;
        width: 136px;
        height: 66px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--grey30);
        border-radius: var(--border-radius-6);
        background: var(--black);
    }
    .showing_custom_pricelist_modal {
        inset: 0;
        position: fixed;
        background: var(--modal-bg);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        > div {
            min-width: 400px;
            background: var(--white);
            padding: 41px 38px 39px 38px;
            position: relative;
            border-radius: var(--border-radius-12);
            border: 1px solid var(--black);
            display: flex;
            flex-direction: column;
            .header {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .close {
                    position: absolute;
                    top: 26px;
                    right: 21px;
                }
            }
            .body {
                // padding-right: 16px;
                > div {
                    margin-top: 10px;
                    input[type="text"],
                    input[type="number"] {
                        height: 31px;
                        border: 1px solid var(--grey30);
                        border-radius: var(--border-radius-4);
                        padding-inline: 10px;
                        width: 100%;
                    }
                }
                .singleprice_cust {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid var(--grey30);
                    border-radius: var(--border-radius-4);
                    > div {
                        width: 34px;
                        padding-inline: 5px;
                        background: var(--grey90);
                        color: var(--white);
                        align-self: stretch;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    input {
                        // width: fit-content !important;
                        text-align: center;
                        border: 0 !important;
                    }
                }
            }
            .footer {
                margin-top: auto;
                margin-left: auto;
                .btn {
                    margin-top: 30px;
                    width: 120px;
                    height: 31px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}
