.pricelistaccordiancardmodal {
  position: fixed;
  inset: 0;
  background-color: var(--modal-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  > div {
    background-color: var(--white);
    width: 600px;
    min-height: 415px;
    border-radius: var(--border-radius-12);
    padding: 41px 37px 29px 38px;
    display: flex;
    flex-direction: column;
    position: relative;
    .pricelistaccordiancardmodal_footer {
      margin-top: auto;
      margin-left: auto;
      div {
        width: 120px;
        height: 31px;
        padding: 7px 0px 6px;
        font-size: var(--f14);
        line-height: var(--lh18);
        border-radius: var(--border-radius-4);
        white-space: nowrap;
      }
    }
    .modal_close {
      position: absolute;
      right: 21.06px;
      top: 26.09px;
    }
    .pricelistaccordiancardmodal_header {
      font-weight: 700;
      font-size: var(--f24);
      line-height: var(--lh31);
      margin-bottom: 3px;
    }
    .pricelistaccordiancardmodal_body {
      margin: 0 0 10px 0;
      .price {
        font-weight: 500;
        font-size: var(--f18);
        line-height: var(--lh23);
        margin-bottom: 25px;
      }
      .body_title {
        font-weight: 700;
        font-size: var(--f16);
        line-height: var(--lh21);
        margin-bottom: 6px;
      }
      .cust_input {
        width: calc(50% - 10px);
        border: 1px solid var(--grey30);
        display: flex;
        align-items: center;
        padding-inline: 10px;
        border-radius: var(--border-radius-4);
        font-weight: 500;
        input {
          font-weight: 500;
          border: 0;
          height: 31px;
          width: 100%;
          font-size: var(--f14);
          line-height: var(--lh18);
          // &.w_50 {
          // }
        }
      }
      .pricelistaccordiancardmodal_weight {
        margin-top: 14px;
      }
      .add_ons_group {
        margin-top: 14px;
        .add_on_flex {
          display: flex;
          // flex-wrap: wrap;
          column-gap: 5px;
          gap: 10px;
          > div {
            width: calc(33% - 5px - (33px / 3));
            // &.price {
            //   width: 20%;
            // }
            &.price_inp {
              border: 1px solid var(--grey30);
              border-radius: var(--border-radius-4);
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              // column-gap: 10px;
              //   padding: 0 10px;
              input {
                width: calc(100% - 40px - 21px);
                border: 0;
                height: 31px;
                pointer-events: none;
              }
              .price_action_btn {
                width: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                &[type="add"] {
                  margin: 0 5px 0 0;
                  height: 100%;
                  background: var(--green);
                  position: relative;
                  &::after {
                    position: absolute;
                    content: "";
                    background: var(--white);
                    height: 50%;
                    width: 3px;
                    inset: 0;
                    margin: auto;
                    border-radius: 5px;
                  }
                  &::before {
                    position: absolute;
                    content: "";
                    background: var(--white);
                    height: 50%;
                    width: 3px;
                    inset: 0;
                    margin: auto;
                    transform: rotate(90deg);
                    border-radius: 5px;
                  }
                }
                &[type="subtract"] {
                  margin: 0 0 0 5px;
                  height: 100%;
                  background: var(--red);
                  position: relative;
                  &::after {
                    position: absolute;
                    content: "";
                    background: var(--white);
                    height: 50%;
                    width: 3px;
                    inset: 0;
                    margin: auto;
                    transform: rotate(90deg);
                    border-radius: 5px;
                  }
                }
                &.disable {
                  background: var(--grey30);
                }
              }
            }
            &.add_btn {
              width: 35px;
              cursor: pointer;
              background: var(--black);
              border-radius: var(--border-radius-4);
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 auto;
            }
          }
        }
        .addon_view {
          margin: 0 0 5px 0;
          &[active="true"] {
            border: 1px solid var(--grey30);
            border-radius: var(--border-radius-4);
            overflow: hidden;
            .table_header {
              background: var(--black);
              color: var(--white);
              padding: 10px;
            }
            > div:not(.table_header) {
              padding: 10px;
            }
            // padding: 10px;
          }
          > div {
            display: flex;
            // padding: 0 0 10px 0;
            width: 100%;
            // border: 1px solid var(--grey30);
            &:not(:last-child) {
              border-bottom: 1px solid var(--grey30);
            }
            > div {
              &:first-child {
                width: 35%;
              }
              &:nth-child(2) {
                width: 35%;
              }
              &:nth-last-child(2) {
                width: 20%;
                text-align: center;
              }
              &:last-child {
                width: 10%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}
