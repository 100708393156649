.cashvieweditmodal {
  position: fixed;
  inset: 0;
  background: var(--modal-bg);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    background: var(--white);
    height: 447px;
    width: 393px;
    border: 1px solid var(--black);
    border-radius: var(--border-radius-12);
    padding: 40px 31px 34px 38px;
    position: relative;
    font-weight: 500;
    .header {
      position: absolute;
      top: 25px;
      right: 31px;
    }
    .body {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      > div {
        width: 50%;
        display: flex;
        flex-direction: column;
        height: 100%;
        .name_view {
          font-size: var(--f25);
          line-height: var(--lh33);
        }
        .amount_view {
          font-size: var(--f25);
          line-height: var(--lh33);
          margin: 0 0 6px 0;
          text-align: center;
          white-space: nowrap;
          text-align: right;
          display: flex;
          justify-content: flex-end;
          > div {
          }
        }

        &.left_body {
          width: 191px;
        }
        &.right_body {
          width: 133px;
          padding: 60px 0 0 0;
          display: flex;
          flex-direction: column;
          height: 100%;
          .cashinout_btn {
            height: 31px;
            margin: auto 0 0 10px !important;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .amount {
            // width: fit-content;
            height: 31px;
            width: 100%;
            margin: 0 0 3px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            // color: var(--red) !important;
            border: 1px solid var(--grey30);
            border-radius: var(--border-radius-4);
            font-size: var(--f14);
            line-height: var(--lh18);
            padding: 0 10px 0 10px;
            &.amount_red {
              color: var(--red) !important;
            }
            &.amount_green {
              color: var(--green) !important;
            }
            input {
              border: 0;
              // color: var(--red) !important;
              &::placeholder {
                // color: var(--red) !important;
              }
            }
            label {
              //   display: flex;
              //   justify-content: center;
              //   align-items: center;
              height: 100%;
              width: 100%;
            }
          }
          input {
            width: 100%;
            text-align: center;
            //     &:first-child {
            //       color: var(--red) !important;
            //       &::placeholder {
            //         color: var(--red) !important;
            //       }
            //     }
          }
        }
      }
      input,
      textarea {
        // width: 100%;
        border: 1px solid var(--grey30);
        padding: 0;
        margin: 0;
        height: 31px;
        border-radius: var(--border-radius-4);
        padding: 0 10px 0 10px;
        font-size: var(--f14);
        line-height: var(--lh18);
        font-weight: 500;
        &::placeholder {
          font-weight: 500;
          color: var(--black);
        }
      }
      textarea {
        resize: none;
        height: fit-content;
        padding: 5px 10px 5px 10px;
        text-align: center;
      }
      .current_date_time {
        margin: 11px 0 0 0;
        font-size: var(--f16);
        line-height: var(--lh21);
      }
      .receipt {
        background: var(--white);
        z-index: 1;
        height: 31px;
        width: 100%;
        border: 1px solid var(--grey30);
        border-radius: var(--border-radius-4);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 55px 0 0 0;
        input {
          display: none;
        }
        svg {
          margin: 0 8px 0 0;
        }
      }
    }
  }
}
