.addorderimagesmodal {
  position: fixed;
  inset: 0;
  background: var(--modal-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  // text-align: center;
  > .addorderimagesmodal_main {
    height: 372px;
    border: 7px solid var(--white);
    // box-shadow: inset 0 0 0px 7px var(--white);
    width: 541.089px;
    // background: var(--white);
    background: var(--black);
    border-radius: var(--border-radius-40);
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    // > img {
    // &[]
    // border-block: 7px solid var(--white);
    // border: 1px solid red;
    // }
    .action_btns {
      display: flex;
      justify-content: space-between;
      // align-items: flex-end;
      align-items: center;
      height: 55px;
      width: 100%;
      background: var(--white);
      // padding-bottom: 7px;
      padding-inline: 26px 31px;

      img {
        cursor: pointer;
        filter: invert(100%);
      }
      .close_img {
        right: 31px;
        top: 35px;
      }
      .add_img {
        input {
          display: none;
        }
        right: 34px;
        bottom: 27px;
      }
      .delete_img {
        left: 26px;
        bottom: 25px;
      }
    }
    .img_div {
      height: calc(372px - 55px - 10px);
      width: 100%;
      position: relative;

      .slick-slider {
        height: 100%;
        width: 100%;
        img {
          margin: auto;
        }
        .slick-arrow.slick-next {
          right: 18px;
          z-index: 10;
          height: 27px;
          width: 15px;
          background: url("../../../../img/icons/arrow_right_icon.svg");
          background-repeat: no-repeat;
          background-size: cover;
          &:before {
            content: "";
          }
        }
        .slick-arrow.slick-prev {
          --svg_current_color: #fff;
          height: 27px;
          width: 15px;
          left: 18px;
          z-index: 10;
          background: url("../../../../img/icons/arrow_left.svg");
          background-repeat: no-repeat;
          background-size: cover;
          &:before {
            content: "";
          }
        }
      }
      .dots_to_show {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        > .dot {
          height: 5px;
          width: 5px;
          background-color: #c4c4c4;
          opacity: 0.7;
          border-radius: 50%;
          transition: all 0.3s linear;
          &.dot_active {
            background-color: var(--white);
            opacity: 1;
          }
          &:not(:last-child) {
            margin-right: 3px;
          }
        }
      }
    }
    > div {
      // position: absolute;
      height: fit-content;
      width: fit-content;

      &.prev_img,
      &.next_img {
        top: 0;
        bottom: 0;
        margin: auto;
        display: none;
      }
      &.prev_img {
        left: 25px;
      }
      &.next_img {
        right: 25px;
      }

      .confirm_delete_image_modal {
        position: absolute;
        cursor: auto;
        inset: 0;
        background: var(--modal-bg);
        color: var(--white);
        height: 100%;
        width: 100%;
        > div {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          .close_img {
            position: absolute;
            right: 31px;
            top: 35px;
          }
          .txt {
            font-size: var(--f26);
            line-height: var(--lh34);
          }
          .btn {
            width: 200px;
            &:first-child {
              margin-top: 16px;
              margin-bottom: 7.77px;
            }
          }
        }
      }
    }
  }
}
