.rte {
  // padding: 20px;
  width: 100%;
  .toolbar {
    display: flex;
    align-items: center;
    column-gap: 5px;
    .tool {
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--modal-bg);
      border-radius: 4px;
    }
    svg {
      //   max-height: 15px;
      height: auto;
      max-width: 15px;
      //   width: auto;
    }
    .RTE_notactive {
      path {
        fill: var(--modal-bg);
      }
    }
    .RTE_active {
      path {
        fill: var(--black);
      }
    }
  }
  .tool_dropdown {
    // border: 1px solid red;
    height: 0px;
    // width: 30px;
    border: 1px solid var(--modal-bg);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 20px 0 10px;
    // overflow: hidden;
    .tool_dropdown_title {
      cursor: pointer;
    }
    .tool_dropdown_main {
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      margin: 0 auto 0 auto;
      // width: fit-content;
      // border: 1px solid red;
      transition: height 0.3s ease-in-out;
      overflow: hidden;
      z-index: 10;
      > div > div {
        cursor: pointer;
        background: var(--white);
        margin: auto;
      }
    }
    .arrow_down {
      position: absolute;
      right: 10px;
      // border: 1px solid red;
      height: 5px;
      aspect-ratio: 1;
      rotate: 45deg;
      box-shadow: inset -1px -1px 0 0 var(--black);
    }
  }
  .rte_editor {
    // border: 1px solid var(--modal-bg);
    padding: 10px;
    margin: 20px 0 0 0;
    // box-shadow: 0 0 10px var(--modal-bg);
    border-radius: 10px;
    border: 1px solid var(--modal-bg);
    // line-height:;
    // font-size: 16px;
    // line-height: 21px;
    > div {
    }
  }
  blockquote {
    border-left: 2px solid #ddd;
    margin: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
  }
  // link style - start
  .editor-wrapper {
    max-width: 600px;
    box-shadow: 2px 2px 1px 1px lightgrey;
    border: 1px solid lightgrey;
    max-width: 600px;
  }

  [data-slate-editor] {
    border-top: 1px solid lightgrey;
    // padding: 0 10px;
  }

  [data-slate-node="element"] {
    // margin: 10px 0;
  }
  .element-link {
    display: inline-block;
    position: relative;
    padding: 10px 20px;
    border: 1px solid var(--black);
    margin: 20px 0;
    // height: 16px;
  }

  .element-link .popup {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 6px 10px;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid lightgray;
  }

  .element-link .popup a {
    all: unset;
    display: flex;
    align-items: center;
    gap: 5px;
    padding-right: 10px;
    border-right: 1px solid lightgrey;
  }

  .element-link .popup button {
    border: none;
    background: transparent;
  }

  .element-link .popup button:hover {
    color: rebeccapurple;
    cursor: pointer;
  }
  // link style -end
}
