.searchandfilterbar {
    display: flex;
    height: 40px;
    width: 100%;
    .filter_img_div {
        display: flex;
        justify-content: center;
        align-items: center;

        height: auto;
    }
    .search_input {
        border: 1.12791px solid var(--grey30);
        border-radius: var(--border-radius-6);
        overflow: hidden;
        width: 100%;
        display: flex;
        align-items: center;
        img {
            margin-left: 14.16px;
            margin-right: 23.3px;
        }
        > input {
            height: 38px;
            width: 100%;
            border: 0;

            padding: 0px;
            &::placeholder {
                color: var(--grey40);
            }
        }
        &:not(.remove_margin_right) {
            margin-right: 11px;
        }
    }
}
