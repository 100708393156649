.custgraph {
  width: 100%;
  height: 100%;
  position: relative;
  // overflow: hidden;
  // overflow: scroll;
  --set_order_height: 10px;
  &::-webkit-scrollbar {
    width: 0px;
    height: 1px;
  }
  &[type="type1"] {
    .y_axis {
      position: sticky;
      top: 0;
      bottom: 0;
      left: 0;
      height: calc(100% - 25px);
      width: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      border-right: 1px solid var(--grey40);
      //   writing-mode: vertical-lr;
      > div {
        height: 100%;
      }
    }
    .x_axis {
      // background: linear-gradient();
      position: absolute;
      bottom: 0;
      height: 25px;
      //   left: 0;
      // right: 0;
      left: 30px;
      min-width: calc(100% - 30px);
      // max-width: fit-content;
      display: flex;
      justify-content: space-around;
      border-top: 1px solid var(--grey40);
      column-gap: 10px;
      // align-items: center;
      .order_item_months {
        --order_count: 1;
        // border: 1px solid red;
        position: relative;
        // flex-grow: 1;
        width: 100%;
        text-align: center;
        // width: 600px;
        > div {
          &.arrow_left {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 12px;
            height: 12px;
            transform: translateY(10px) rotate(45deg);
            color: transparent;
            box-shadow: inset 3px -3px 0 0 var(--black);
          }
          &.arrow_right {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 12px;
            height: 12px;
            transform: translateY(10px) rotate(45deg);
            color: transparent;
            box-shadow: inset -3px 3px 0 0 var(--black);
          }
          &.arrow_line {
            width: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2px;
            transform: translateY(15px);
            color: transparent;
            background: var(--black);
            // position: relative;
            // &:after {
            //   content: "-";
            //   position: absolute;
            //   inset: 0;
            // }
          }
        }
        .order_items_group {
          position: absolute;
          bottom: 100%;
          left: 0;
          right: 0;
          margin: 0 auto;
          // width: fit-content;
          max-width: 30px;
          //   display: flex;
          display: flex;
          flex-direction: column-reverse;

          > div {
            writing-mode: horizontal-tb !important;
            color: var(--white);
            white-space: nowrap;
            height: calc(var(--def_height) * 1px);
            --curr_width: calc(100% / var(--order_count));
            // width: var(--curr_width);
            width: 100%;
            // margin: 0 auto;
            // border: 1px solid blue;
            background: var(--grey40);
            background: var(--background_color);
            // border-top-left-radius: 5px;
            // border-bottom-right-radius: 5px;
            // border-radius: var(--border-radius-4);
            transform: translateX(calc(100% * var(--order_num) * 1));
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-top: 1px solid var(--white);
            &:not(:last-child) {
            }
            &.show_order_details {
              position: absolute;
              // inset: 0;
              top: 0;
              left: 30px;
              height: 100px;
              border: 1px solid;
              // background: var(--white);
              z-index: 10;
              opacity: 0;
              white-space: nowrap;
              // padding: 10px 20px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 0px;
              transition: all 0.3s linear;
              overflow: hidden;
              background: var(--white);
              > div {
                width: calc(100% - 40px);
              }
              &.active {
                width: 200px;
                opacity: 1;
              }
              // transform: translate(100%);
            }
            &.order_items_main {
              position: relative;
              --bottom_percentage: 100%;
              z-index: 0;
              &:after {
                content: "";
                height: 10px;
                width: 10px;
                background: var(--background_color);
                position: absolute;
                top: 0;
                // left: 0;
                bottom: 0;
                right: 0;
                margin: auto 0;
                transform: translate(50%) rotate(45deg);
                opacity: 0;
              }
              .order_items_modal {
                z-index: 100;
                //   z-index: 100;
                //   transform: translate(0);
                position: absolute;
                // border: 1px solid red;
                border: 1px solid var(--grey40);
                color: var(--black);
                background: var(--white);
                height: fit-content;
                //   bottom: 100%;
                // bottom: calc(var(--bottom_percentage));
                //   right: calc(var(--bottom_percentage));
                //   right: calc((var(--bottom_percentage)) * -1);
                left: calc(100% + 10px);
                bottom: 0;
                top: 0;
                margin: auto 0;
                padding: 10px;
                width: 200px !important;
                text-align: left;
                // background: red;
                // bottom: calc(100% + 10px);
                // &[type="true"] {
                //   left: unset;
                //   right: calc(100% + 10px);
                // }
                display: flex;
                flex-direction: column;
                border-radius: var(--border-radius-10);
                > div {
                  width: 100%;
                }
              }
              .order_items_modal {
                // border: 1px solid red !important;
                pointer-events: none;
                // transition: all 0.3s linear;
                z-index: -1;
                // transition: opacity 0.3s linear;
                &[type="false"] {
                  // width: 0;
                  overflow: hidden;
                  opacity: 0;
                  // transform: scale(0);
                }
                &[type="true"] {
                  width: 150px;
                  overflow: hidden;
                  opacity: 1;
                  // transform: scale(1);
                }
              }
              &[type_horizontal="true"] {
                &:after {
                  // background: red;
                  right: unset;
                  left: 0;
                  transform: translate(-50%) rotate(45deg);
                  // opacity: 0;
                }
                .order_items_modal {
                  // border: 1px solid red !important;
                  right: calc(100% + 10px);
                  left: unset;
                  // &[type="false"] {
                  //   width: 0;
                  // }
                }
              }
              &[type_horizontal="true"] {
                &:after {
                  // background: red;
                  right: unset;
                  left: 0;
                  transform: translate(-50%) rotate(45deg);
                  // opacity: 0;
                }
                .order_items_modal {
                  // border: 1px solid red !important;
                  right: calc(100% + 10px);
                  left: unset;
                  // &[type="false"] {
                  //   width: 0;
                  // }
                }
              }
            }
            // border: 1px solid red;
            &:hover {
              z-index: 100;
              box-shadow: 0 0 0 1px var(--black);
              border: 0;
              &:after {
                box-shadow: 1px -1px 0 0px var(--black);
                opacity: 1;
              }
              &[type_horizontal="true"] {
                &:after {
                  box-shadow: -1px 1px 0 0px var(--black);
                }
              }
            }
          }
        }
      }
      // &[type="1"] {
      //   // border: 1px solid red;
      // }
    }
    .year {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: fit-content;
    }
  }
}
