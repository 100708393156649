.cashmenu {
  position: absolute;
  right: 100%;
  top: 0px;
  background: var(--white);
  border: 1px solid var(--black);
  border-radius: var(--border-radius-10);
  padding: 12px 11px 21px 23px;
  height: 106px;
  width: 174px;
  .header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0 0 17px 0;
    svg {
      margin: 0 0 0 auto;
    }
  }
  .body {
    font-size: var(--f12);
    line-height: var(--lh16);
    > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      svg {
        width: 17px;
        margin: 0 19px 0 0;
      }
      &:not(:last-child) {
        margin: 0 0 15px 0;
      }
    }
  }
}
