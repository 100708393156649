.draftorder {
    // padding-right: 304px !important;
    padding-right: 20% !important;

    .filter_btn_group {
        display: flex;
        .filter_card {
            margin-top: 24px;
            background: var(--black);
            // width: 138px;
            height: 35px;
            border-radius: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-inline: 20px 13px;
            color: var(--white);
            font-weight: 500;
            font-size: var(--f13);
            line-height: var(--lh17);
            margin-right: 8px;

            img {
                margin-left: 9px;
                cursor: pointer;
            }
        }
    }

    .draftorder_body {
        .draftorder_table {
            margin-top: 23px;
        }
    }
}
