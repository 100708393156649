.printorder {
  padding: 0 !important;
  width: 80vw;
  margin: auto;
  //   height: fit-content;
  .order_id {
    font-weight: 900;
    font-size: 45px;
    // line-height: var(--lh50);
  }
  .order_name {
    font-weight: 500;
    font-size: 20px;
    // line-height: var(--lh34);
    text-transform: capitalize;
  }
  .order_address {
    font-weight: 500;
    font-size: 20px;
    // line-height: var(--lh34);
    text-transform: capitalize;
  }
  .order_time {
    font-weight: 500;
    font-size: 13px;
    // line-height: var(--lh21);
    text-transform: uppercase;
    // margin: 0 0 10px 0;
    margin: 10px 0 10px 0;
  }
  .dotted_lines {
    // border-top: 1px dashed var(--black);
    height: 1px;
    background-image: linear-gradient(
      to right,
      black 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: left bottom;
    background-size: 20px 1px;
    background-repeat: repeat-x;
    &.mb {
      margin: 0 0 5px 0;
    }
    &.mbt {
      margin: 10px 0 10px 0;
    }
  }
  .order_items {
    margin: 10px 0 0 0;
    padding: 0 0 0 10px;
    .items_group {
      display: flex;
      justify-content: space-between;
      .name {
        width: 85%;
        // border: 1px solid red;
        overflow: hidden;
        text-overflow: ellipsis;
        .addons_group {
          margin: 0 0 0 30px;
          opacity: 0.7;
        }
        .main_item {
          display: flex;
        }
      }
    }
  }
  .total_group {
    // width: 40%;
    margin: 0 0 0 auto;
    > div {
      display: flex;
      justify-content: space-between;
    }
    .final_total {
      margin: 0 0 30px 0;
    }
  }
  .print_group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    > div {
      width: 50%;
    }
  }
  .printer_modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0;
    background: var(--modal-bg);
    z-index: 100;
    > .main {
      background: var(--white);
      width: 334px;
      height: 436px;
      border: 1px solid var(--black);
      border-radius: 12px;
      padding: 25px 22px 22px 38px;
      display: flex;
      flex-direction: column;
      .header {
        margin: 0 0 0 auto;
      }
      .title {
        margin-bottom: 6px;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.9975px;
      }
      > .body {
        .cust_inp {
          width: 191px;
          height: 31px;
          // width: 191px;
          border: 1px solid var(--black);
          border-radius: var(--border-radius-4);
          overflow: hidden;
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 500;
          font-size: var(--f14);
          line-height: var(--lh18);
          letter-spacing: 0.9975px;
          > input {
            width: 100%;
            height: 100%;
            border: 0;
            padding: 0 10px;
            font-weight: 500;
            font-size: var(--f14);
            line-height: var(--lh18);
            &::placeholder {
              font-weight: 500;
              font-size: var(--f14);
              line-height: var(--lh18);
              color: var(--black);
            }
          }
          &.input_switcher {
            .input_switcher_main {
              position: relative;
              display: flex;
              align-items: center;
              height: 100%;
              width: calc(100% + 25px);
              transition: transform 0.3s linear;
              .inp_icon {
                flex: 0 0 25px;
                height: 31px;
                line-height: 31px;
                //   width: 20px;
                text-align: center;
              }
              input {
                //   width: 164px;
                width: calc(100% - 25px);
                height: 100%;
                border: 0;
                transition: padding 0.3s linear;
                //   padding: 0;
              }
            }
          }
        }
      }
    }
  }
}
@page {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0;
  -webkit-print-color-adjust: exact;
}
@media print {
  .navbbar {
    display: none;
  }
  .print_group {
    display: none;
  }
}
