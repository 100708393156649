.add_order {
  .addorder {
    padding-top: 21px;
    padding-bottom: 54px;
    // border: 1px solid red;
    display: flex;
    // height: calc(100vh - 121px);
    height: 100%;
    .current_data_card {
      width: 368px;
      background: var(--black);
      color: var(--white);
      border-radius: var(--border-radius-20);
      padding: 33px 59px 27px 37px;
      display: flex;
      flex-direction: column;
      > div {
        font-weight: 500;
      }

      .data_name {
        font-size: var(--f30);
        line-height: var(--lh39);
        margin-bottom: 14px;
      }
      .data_address,
      .data_phone,
      .data_ccnumber {
        font-size: var(--f14);
        line-height: var(--lh18);
      }
      .data_address {
        margin-bottom: 4px;
      }
      .data_phone {
        margin-bottom: 5px;
      }
      .data_clientparticulars {
        margin-top: 0px;
        // border-bottom: 1px solid var(--white);
        padding: 5px 10px;
        // border-radius: 10px;
        position: relative;
        > div:not(.particular_title) {
          margin: 0 0 0 20px;
          position: relative;
          &:before {
            content: "";
            height: 1px;
            width: 5px;
            background: var(--white);
            position: absolute;
            left: -15px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
        &:after {
          content: "";
          height: 1px;
          width: 368px;
          background: var(--white);
          // background: red;
          position: absolute;
          left: -37px;
          bottom: 0;
          bottom: 0;
          margin: auto;
        }
      }
      .data_order_items {
        margin-block: 10px 20px;
        // height: 100vh;
        overflow-y: scroll;
        // padding-bottom: 0;
        // background-color: aqua;
        overflow-x: unset;
        margin-right: -44px;
        > .order_item_cards {
          padding-right: 19px;
          display: flex;
          width: 100%;
          position: relative;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          > .order_item_name {
            &:first-child {
              flex-grow: 1;
              display: flex;
              flex-wrap: wrap;
              margin-right: 5px;
              border: 1px solid var(--white);
              border-radius: var(--border-radius-6);
              padding: 14px 17px 13px 16px;
              justify-content: space-between;
              // border: 1px solid red;
              // flex-wrap: wrap;
              .item_count {
                width: 50px;
                display: flex;
                justify-content: space-between;
                padding: 0 10px 0 0;
              }
              .item_name {
                width: calc(100% - 150px);
                margin: 0 auto 0 0;
              }
              .item_price {
                // width: calc(100px);
                text-align: right;
              }
              .item_addon_group {
                width: 100%;
                // border: 1px solid red;
                .divider_line {
                  border-top: 1px solid var(--grey40);
                  margin: 10px 0 0 0;
                }
                .card_total {
                  // border-top: 1px solid var(--grey40);
                  // margin: 10px 0 0 0;
                  padding: 10px 0 0 0;
                  display: flex;
                  justify-content: space-between;
                }
                .card_discount_total {
                  margin: 5px 0 0 0;
                  padding: 0px 0 0 0;
                  display: flex;
                  justify-content: space-between;
                }
                > .item_addonitem {
                  margin: 10px 0 0 0;
                  display: flex;
                  // column-gap: 10px;
                  .addon_list {
                    width: 30px;
                  }
                  .addon_name {
                    width: calc(100% - 30px - 100px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    > div {
                      &:first-child {
                        font-size: var(--f9);
                        line-height: var(--lh12);
                        color: var(--grey40);
                      }
                    }
                  }
                  .addon_price {
                    width: 100px;
                    text-align: right;
                  }
                }
              }
            }
            &:last-child {
              margin-left: auto;
              img {
                filter: invert(100%);
              }
            }
          }
          .delete_order_item {
            // position: absolute;
            // left: 100%;
            &.not_show {
              visibility: hidden;
            }
          }
        }
        .item_name {
          .name_part3 {
            display: flex;
            flex-wrap: wrap;
            > div {
              &:last-child {
                width: 100%;
              }
              &:not(:last-child) {
                // width: 50%;
                font-size: var(--f9);
                line-height: var(--lh12);
                color: var(--grey40);
              }
              &:first-child {
                margin-right: 5px;
              }
            }
          }
          .name_part2 {
            display: flex;
            flex-wrap: wrap;
            > div {
              &:last-child {
                width: 100%;
              }
              &:not(:last-child) {
                // width: 50%;
                font-size: var(--f9);
                line-height: var(--lh12);
                color: var(--grey40);
              }
              &:first-child {
                margin-right: 5px;
              }
            }
          }
          > .weight_div {
            margin-top: 6px;
            font-weight: 400;
            font-size: var(--f12);
            line-height: var(--lh16);
          }
        }
      }
      .data_subtotal,
      .data_discount,
      .data_total {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .data_subtotal {
        font-size: var(--f16);
        line-height: var(--lh21);
        margin-top: auto;
      }
      .data_discount {
        font-size: var(--f14);
        line-height: var(--lh18);
      }
      .data_total {
        font-size: var(--f16);
        line-height: var(--lh21);
        margin-top: 13px;
        padding-top: 16px;
        border-top: 1px solid var(--white);
      }
    }
    .current_data_options {
      width: calc(100% - 368px);
      padding-left: 71px;
      .order_menu {
        display: flex;
        justify-content: space-between;
        font-size: var(--f14);
        line-height: var(--lh18);
        color: var(--grey40);
        > [add_order_menu_active="true"] {
          color: var(--black);
          font-weight: 700;
        }
        .order_menu_tabs {
          cursor: pointer;
        }
      }
      .order_menu_body {
        .continue_to_tags {
          margin-top: 15px;
          .btn {
            margin-left: auto;
            width: 200px;
          }
        }
      }
    }
  }
  .pricelist_item_delete_modal {
    position: fixed;
    inset: 0;
    background: var(--modal-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      width: 464px;
      height: 400px;
      border-radius: var(--border-radius-12);
      border: 1px solid var(--black);
      background: var(--white);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .txt {
        font-style: normal;
        font-weight: 700;
        font-size: var(--f30);
        line-height: var(--lh39);
      }
      .btn {
        width: 279px;
        height: 47px;
        border: 1px solid var(--black);
        &:first-child {
          margin-block: 31px 10px;
        }
      }
    }
  }
}
