.viewedititemcategorymodal {
  position: fixed;
  inset: 0;
  background: var(--modal-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  > div {
    padding: 41px 20px 24px 38px;
    background: var(--white);
    // width: 298px;
    min-height: 397px;
    height: max-content;
    flex-grow: 0;
    border: 1px solid var(--black);
    border-radius: var(--border-radius-12);
    position: relative;
    display: flex;
    flex-direction: column;
    .head {
      svg {
        position: absolute;
        top: 25px;
        right: 16px;
      }
    }
    .body {
      display: flex;
      padding: 0 25px 20px 0;
      > div {
        &:first-child {
          width: 189px;
          .cust_input {
            border: 1px solid var(--modal-bg) !important;
            pointer-events: none !important;
          }
        }
        &:last-child {
          padding: 0 0 0 25px;
          width: 0;
          overflow: hidden;
          transition: width 0.3s linear;
          white-space: nowrap;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          > .title:first-child {
            margin: 0 0 auto 0;
          }
          &.active {
            width: calc(189px + 25px);
          }
        }
        // padding: 0 49px 20px 0;
        // padding-right: 49px;
        // padding-bottom: 20px;
      }
      .title {
        font-weight: 700;
        font-size: var(--f16);
        line-height: var(--lh21);
        margin-bottom: 6px;
        &:not(:first-child) {
          margin-top: 14px;
        }
      }
      .cust_input {
        border-radius: var(--border-radius-4);
        border: 1px solid var(--black);
        overflow: hidden;
        display: flex;
        align-items: center;

        font-weight: 500;
        padding-inline: 10px;
        font-size: var(--f14);
        line-height: var(--lh16);
        height: 31px;
        input {
          font-weight: 500;
          border: 0;
          width: 100%;
          // padding-block: 6px;
          &::placeholder {
            color: var(--black);
          }
        }
        &.input_padding_5 {
          input {
            padding-inline: 5px;
          }
        }
        > div {
          white-space: nowrap;
        }
      }
      .border_black {
        .default_txt {
          border-color: var(--black);
        }
      }
    }
    .footer {
      margin-top: auto;
      margin-left: auto;
      display: flex;
      column-gap: 10px;
      .btn {
        // transition: width 0.3s linear;
        width: 0px;
        height: 31px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--f12);
        line-height: var(--lh16);
        width: 100px;
        &.animate {
          width: 0px;
          animation: width-inc forwards 0.3s linear;

          @keyframes width-inc {
            0% {
              width: 0px;
            }
            100% {
              width: 100px;
            }
          }
        }
      }
    }
  }
}
