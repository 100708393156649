.settings_main {
  .page_divider {
    // display: flex;
    .page_nav {
      //   border: 1px solid red;
      //   width: 30%;
      display: flex;
      flex-direction: column;
      gap: 10px 0;
      > div {
        display: flex;
        padding: 10px 20px;
        border: 1px solid var(--grey30);
        border-radius: 5px;
        svg {
        }
      }
    }
    // .page_view {
    //   //   border: 1px solid red;
    //   //   width: 70%;
    // }
    .printer_view {
      width: 50%;
      .title {
        margin-bottom: 6px;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.9975px;
      }
      .btn {
        margin: 10px 0 0 0;
      }
      .cust_inp {
        height: 31px;
        // width: 191px;
        border: 1px solid var(--black);
        border-radius: var(--border-radius-4);
        overflow: hidden;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 500;
        font-size: var(--f14);
        line-height: var(--lh18);
        letter-spacing: 0.9975px;
        > input {
          width: 100%;
          height: 100%;
          border: 0;
          padding: 0 10px;
          font-weight: 500;
          font-size: var(--f14);
          line-height: var(--lh18);
          &::placeholder {
            font-weight: 500;
            font-size: var(--f14);
            line-height: var(--lh18);
            color: var(--black);
          }
        }
        &.input_switcher {
          .input_switcher_main {
            position: relative;
            display: flex;
            align-items: center;
            height: 100%;
            width: calc(100% + 25px);
            transition: transform 0.3s linear;
            .inp_icon {
              flex: 0 0 25px;
              height: 31px;
              line-height: 31px;
              //   width: 20px;
              text-align: center;
            }
            input {
              //   width: 164px;
              width: calc(100% - 25px);
              height: 100%;
              border: 0;
              transition: padding 0.3s linear;
              //   padding: 0;
            }
          }
        }
      }
    }
  }
}
