.draftorder {
  // padding-right: 304px !important;
  // padding-left: 50px !important;
  padding-right: 0px !important;
  // max-width: 1500px;
  margin: 0 auto 0 auto;
  .draftmenu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: var(--f16);
    line-height: var(--lh21);
    font-weight: 900;
    color: var(--grey40);
    padding-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--grey20);
    > div {
      cursor: pointer;
    }
    .active {
      color: var(--black);
    }
  }
  .filter_btn_group {
    display: flex;
    .filter_card {
      margin-top: 24px;
      background: var(--black);
      // width: 138px;
      height: 35px;
      border-radius: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-inline: 20px 13px;
      color: var(--white);
      font-weight: 500;
      font-size: var(--f13);
      line-height: var(--lh17);
      margin-right: 8px;

      // img {
      //     margin-left: 20px;
      //     cursor: pointer;
      // }
    }
  }
  .order_states {
    display: flex;
    white-space: nowrap;
    flex-wrap: wrap;
    column-gap: 10px;
    justify-content: center;
    margin: 5px 0 5px 0;
    cursor: pointer;
    border-bottom: 1px solid var(--grey20);
    > div {
      // padding: 10px;
      background-color: #fff;
      color: #000;
      padding: 5px 10px;
      border-radius: 30px;
      margin: 7px 0 7px 0;
      transition: all 0.3s linear;
      // border: 1px solid var(--grey40);
      border: 1px solid var(--black);
      // background: red;
      &.active {
        // border: 1px solid var(--black);
        background-color: #000;
        color: #fff;
      }
    }
  }
  .draftorder_body {
    .draftorder_table {
      // border: 1px solid red;
      margin-top: 23px;
      .table_heading {
        margin: 0;
        padding: 0 0 10px 0;
        border-bottom: 1px solid var(--grey20);
      }
      // .column4 {
      //   // overflow: hidden;
      //   // text-overflow: ellipsis;
      //   // width: fit-content;
      // }
      .column3,
      .column5 {
        // padding: 0 10px 0 0;
        justify-content: center;
        text-align: center;
      }
      .table_body {
        // background: red;
        height: calc(100vh - 400px);
        @media screen and (max-width: 500px) {
          height: calc(100vh - 200px);
        }
        padding: 0;
        .column5,
        .column3 {
          justify-content: center;
          text-align: center;
        }
      }
      &.smaller_height {
        .table_body {
          height: calc(100vh - 400px);
        }
      }
    }
  }
}
