*[table*="type"] {
  .table_heading,
  .table_body .row {
    display: flex;
    align-items: center;
    color: var(--black);
    font-size: var(--f16);
    line-height: var(--lh21);
    font-weight: 500;
  }
  .table_heading {
    color: var(--grey40);
    font-size: var(--f13);
    line-height: var(--lh17);
    margin-bottom: 17px;
    text-transform: uppercase;
  }
  .table_body {
    overflow-y: scroll;
    // overflow-x: visible;
    > div:not(:last-child) {
      // margin-bottom: 30px;
      margin-bottom: 0px;
    }
    .table_action {
      margin-left: auto;
    }
  }
  &.order_table {
    .row {
      padding: 0 0 23px 0;
    }
    .column1 {
      width: 18%;
    }
    .column2 {
      width: 22%;
    }
    .column3 {
      width: 24%;
    }
    .column4 {
      width: 19%;
      [ordertablecolor="0"] {
        color: var(--green);
      }
      [ordertablecolor="1"] {
        color: var(--red);
      }
      [ordertablecolor="2"] {
        color: var(--red);
      }
    }
    .column5 {
      width: 17%;
      display: flex;
      align-items: center;
      img {
        margin-left: auto;
      }
    }
    .table_body {
      height: calc(100vh - 366px);
    }
    &.order_table_mini {
      .table_body {
        // 59px is height of filter cards above the table
        height: calc(100vh - 366px - 50px);
      }
    }
  }
  &.draftorder_table {
    > div {
      > div {
        > div {
          white-space: nowrap;
          overflow: hidden;
          // border: 1px solid red;
          text-overflow: ellipsis;
          &.column2 {
            padding: 0 10px 0 0px;
          }
          &.column3,
          &.column3 {
            padding: 0 5px 0 5px;
          }
        }
      }
    }
    .column1 {
      // background: red;
      width: calc(calc(100% - 120px) * 0.07);
      // width: 30%;
      padding-right: 15px;
    }
    .column2 {
      width: calc(calc(100% - 120px) * 0.24);
      // width: 30%;
    }
    .column3 {
      width: calc(calc(100% - 120px) * 0.14);
      // width: 40%;
      display: flex;
      justify-content: flex-start;
      img {
        margin-left: auto;
        margin-right: 30px;
      }
    }
    .column4 {
      // display: flex;
      // align-items: center;
      width: calc(calc(100% - 120px) * 0.24);
      overflow: hidden;
      text-overflow: ellipsis;
      // padding: 0 100px 0 0;
      // border: 1px solid red;
    }
    .column5 {
      width: calc(calc(100% - 120px) * 0.15);
    }
    .column6 {
      width: 120px;
    }
    .column7 {
      width: calc(calc(100% - 120px) * 0.15);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      overflow: visible;
      svg {
        margin-inline: 10px !important;
      }
    }

    .table_body {
      // height: calc(100vh - 206px);
      .row {
        // height: 21px;
        height: 50px;
      }
    }
    // &.draftorder_table_mini {
    //   .table_body {
    //     height: calc(100vh - 204px - 60px);
    //   }
    // }
    &.reports_table {
      // border: 1px solid red;
      .column1 {
        width: 23%;
        text-align: left !important;
      }
      .column2 {
        width: 22%;
        text-align: left !important;
      }
      .column3 {
        display: unset;
        width: 18%;
        text-align: left !important;
      }
      .column4 {
        width: 20%;
        text-align: left !important;
      }
      .column5 {
        width: 15%;
        text-align: center !important;
      }
      .column6 {
        width: 18px;
        text-align: left !important;
      }
    }
  }
  &.cash_table {
    margin: 23px 0 0 0;
    // > div {
    //   > div {
    //     > div {
    //       white-space: nowrap;
    //       overflow: hidden;
    //       // border: 1px solid red;
    //       text-overflow: ellipsis;
    //       padding: 0 10px 0 0;
    //     }
    //   }
    // }
    .column1 {
      width: 23%;
    }
    .column2 {
      width: 17%;
    }
    .column3 {
      width: 24%;
      // display: flex;
      // justify-content: flex-start;
      // img {
      //   margin-left: auto;
      //   margin-right: 30px;
      // }
    }
    .column4 {
      // display: flex;
      // align-items: center;
      width: 16%;
      // overflow: hidden;
    }
    .column5 {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 14%;
    }
    .column6 {
      width: 6%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      // > div {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      // }
    }
    // .column7 {
    //   width: calc(calc(100% - 120px) * 0.18);
    //   display: flex;
    //   justify-content: flex-end;
    //   align-items: center;
    //   svg {
    //     margin-inline: 10px !important;
    //   }
    // }

    .table_body {
      height: calc(100vh - 435px);
      .row {
        // // height: 21px;
        // height: 50px;
        div:not(.column6) {
          // border: 1px solid red;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:not(:last-child) {
          margin: 0 0 27px 0;
        }
      }
    }
    // &.draftorder_table_mini {
    //   .table_body {
    //     height: calc(100vh - 204px - 60px);
    //   }
    // }
  }
  .child_row {
    display: flex;
    align-items: center;
  }
  &.vendor_table {
    // border: 1px solid red;
    // max-width: 530px;
    width: 100%;
    margin: 23px 0 0 0;
    .column1 {
      width: 32%;
    }
    .column2 {
      width: 40%;
    }
    .column3 {
      width: 25%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .column4 {
      width: 3%;
    }
    .table_body {
      height: calc(100vh - 280px - 102px);
      // border: 1px solid red;
      .row {
        margin: 0 0 30px 0;
      }
    }
    // border: 1px solid red;
    transition: width 0.3s linear;
    &.vendor_table_mini {
      width: 220px;
      padding: 0 20px 0 0;
      border-right: 1px solid var(--black);
      .table_heading {
        height: 0;
        overflow: hidden;
      }
      .table_body {
        .row {
          .name {
            border: 1px solid var(--grey30);
            border-radius: 6px;
            padding: 7px 12px 6px 12px;
            transition: border 0.3s linear;
            &.active {
              border: 1px solid var(--black);
            }
          }
          margin: 0;
          &:not(:first-child) {
            margin: 15px 0 0 0;
          }

          .column1 {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 500;
            font-size: var(--f14);
            line-height: var(--lh18);
          }
          .column2 {
            display: none;
          }
          .column3 {
            display: none;
          }
          .column4 {
            display: none;
          }
        }
      }
    }
  }
  &.discount_table {
    margin: 22px 0 0 0;
    .column1 {
      width: calc(50% - 50px);
    }
    .column2 {
      width: calc(50% - 50px);
    }
    .column3 {
      width: 100px;
      text-align: center;
    }
    .table_body {
      // border: 1px solid red;-
      height: calc(100vh - 200px);
      // overflow-x: hidden;
      // border: 1px solid red;
      // .row {
      //   margin: 0 0 30px 0;
      // }
      .row {
        margin: 0 0 28px 0;
      }
      .column3 {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        // > svg,
        // > div {
        //   // height: 20px;
        //   // width: 20px;
        // }
        // > div {
        // height: fit-content;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // border-radius: 4px;
        // }
      }
    }
  }
  &.team_table {
    .column1 {
      width: 30%;
      padding-right: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    // .column2 {
    //   width: 33%;
    // }
    .column2 {
      width: 52%;
      padding-right: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .column3 {
      width: 15%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .column4 {
      width: 3%;
    }
    .table_heading {
      margin: 23px 0 17px 0;
    }
    .table_body {
      // border: 1px solid red;
      height: calc(100vh - 215px);
    }
    .row {
      > div {
        margin: 0 0 30px 0;
        &:not(.column4) {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
  &.vendorcash_table {
    text-align: center;
    height: calc(100% - 100px);
    .column1 {
      width: calc((100% - 10px) / 3);
    }
    .column2 {
      width: calc((100% - 10px) / 3);
    }
    .column3 {
      width: calc((100% - 10px) / 3);
    }
    .column4 {
      width: 10px;
    }
    .table_heading {
      margin-block: 10px;
    }
    .table_body {
      height: calc(100% - 17px);
    }
    .row {
      padding: 5px 0 5px 0;
    }
  }
}
