.login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  > svg {
    width: min(420px, 80vw);
  }
  .login_inputs {
    margin-block: 44px;
    width: min(420px, 80vw);
    input {
      text-align: center;
      height: 59px;
      width: 100%;
      border: 1px solid var(--black);
      border-radius: var(--border-radius-4);
      padding-block: 16.5px;
      color: var(--black);
      font-size: var(--f20);
      line-height: var(--lh26);
      font-weight: 500;
      &::placeholder {
        font-weight: 500;
        color: var(--black);
      }
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
  .login_btn {
    width: min(420px, 80vw);
  }
  .error {
    margin: 10px 0 0 0;
    color: var(--red);
    overflow: hidden;
    transition: all 0.2s linear;
    height: 0px;
    &.show {
      height: 33px;
    }
    > div {
      border-radius: 5px;
      border: 1px solid var(--red);
      padding: 5px 10px;
    }
  }
}
