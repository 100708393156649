.navbar {
  width: 91px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--black);
  transform: translateX(-100%);
  transition: all 0.3s linear;
  padding-top: 32.29px;
  pointer-events: none;
  z-index: 10;
  .navbar_main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    > div {
      margin-left: 34px;
    }
    .menu {
      margin-left: 31px;
    }
  }
  &[shownavbar="true"] {
    pointer-events: unset;
    transform: translateX(0%);
  }
  .nav_tab {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    color: var(--grey40);
    &:not(:last-child) {
      margin-bottom: 30px;
    }
    &.logout {
      margin: auto 0 30px 34px;
      path {
        fill: #fff;
      }
    }
    svg {
      height: 24px !important;
      width: 24px !important;
      margin-right: 26px;
      cursor: pointer;
      > path {
        transition: all 0.2s linear;
      }
    }
    .nav_tab_txt {
      width: fit-content;
      opacity: 0;
      overflow: hidden;
      white-space: nowrap;
      transition: all 0.3s linear;
    }
    &.nav_tab_active_fill {
      svg > path {
        fill: var(--white);
      }
      color: var(--white);
    }
    &.nav_tab_active_stroke {
      svg > path {
        stroke: var(--white);
      }
      color: var(--white);
    }
  }
  .menu {
    cursor: pointer;
    height: 30px;
    width: 30px;
    padding: 8px 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 40px;
    margin-right: auto;
    transition: all 0.3s linear;
    .menu_bar {
      width: 100%;
      height: 2px;
      background-color: var(--white);
      border-radius: 2px;
      transition: all 0.3s linear;
      &.bar1 {
        transform-origin: bottom left;
      }
      &.bar2 {
        transform-origin: left;
      }
      &.bar3 {
        transform-origin: top left;
      }
    }
  }
  &.navbar_active {
    width: 220px;
    .nav_tab {
      .nav_tab_txt {
        opacity: 1;
      }
    }
    .menu {
      // padding: 8px 8px;
      transform: translate(5px);
      --menu-bar-width-decreased-to: 70%;
      .bar1 {
        width: var(--menu-bar-width-decreased-to);
        transform: rotate(45deg);
      }
      .bar2 {
        transform: scaleX(0);
      }
      .bar3 {
        width: var(--menu-bar-width-decreased-to);
        transform: rotate(-45deg);
      }
    }
  }
}
