.faq {
  .faq_body {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    column-gap: 20px;
    //   height: 40px;
    // padding: 0 110px 0 0;
    height: calc(100vh - 46px);
    margin: 0 0 auto 0;
    .faq_header {
      display: flex;
      column-gap: 20px;
      .btn {
        width: 200px;
        cursor: pointer;
        height: 38px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 12px;
        font-weight: 500;
        font-size: var(--f24);
        line-height: var(--lh31);
        svg {
          path {
            stroke-width: 4;
          }
        }
      }
      > div:first-child {
        width: calc(100% - 200px);
      }
    }
    // .faq_body_left {
    //   width: calc(100% - 200px);
    .question_answer_table {
      max-height: calc(100vh - 46px);
      overflow-y: scroll;
      margin: 2px 0 0 0;
      .row {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 20px;
        > div {
          // overflow: hidden;
          width: 50%;
        }
        &.heading {
          color: var(--grey40);
          font-weight: 500;
          font-size: var(--f13);
          line-height: var(--lh17);
          margin: 0 0 11px 0;
        }
        &.body {
          > .col1,
          > .col2 {
            height: 100px;
            border-radius: var(--border-radius-6);
            padding: 14px 17px 13px 16px;
            border: 1px solid var(--black);
            font-weight: 400;
            font-size: var(--f14);
            line-height: var(--f18);
            //   text-transform: lowercase;
            margin: 0 18px 14px 0;
            // overflow: hidden;
            .rte {
              height: 100% !important;
              overflow: hidden;
              text-overflow: ellipsis;
              .rte_editor {
                border: 0 !important;
                margin: 0 !important;
                padding: 0 !important;
              }
            }
          }
          // > .col2 {
          //   // position: relative;
          // }
          .col3 {
            position: relative;
            width: 20px;
            align-self: flex-start;

            .menu_main {
              background: var(--white);
              position: absolute;
              top: 0;
              right: calc(100% + 10px);
            }
            .menu_main {
              // left: calc(100% + 50px);
              width: 174px;
              height: 0px;
              overflow: hidden;
              transition: height 0.2s linear;
              &[active="true"] {
                height: 106px;
              }
              > div {
                border-radius: var(--border-radius-10);
                border: 1px solid var(--black);
                padding: 14px 13px 26px 23px;
              }
              .header {
                display: flex;
                justify-content: flex-end;
                margin: 0 0 9px 0;
              }
              .body {
                > div {
                  cursor: pointer;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  column-gap: 18.5px;
                  font-weight: 500;
                  font-size: var(--f12);
                  line-height: var(--lh16);
                  &:not(:last-child) {
                    margin: 0 0 14px 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    // }
    .faq_body_right {
      width: 200px;
      height: 40px;
      > .btn {
        height: 100%;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 12px;
        font-weight: 500;
        font-size: var(--f24);
        line-height: var(--lh31);
        svg {
          path {
            stroke-width: 4;
          }
        }
      }
    }
  }
}
