.link_icon_selector {
  //   width: 50px;
  //   width: 100px;
  width: 100%;
  height: 35px;
  .menu {
    border: 1px solid var(--grey40);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    // padding: 3px;
    height: 100%;
    width: 100%;
    // padding: 2.5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    .menu_icon_list {
      position: relative;
      //   width: 100%;
      height: 20px;
      width: 20px;
      > svg {
        position: absolute;
        height: 20px;
        width: 20px;
        top: 0;
        left: 0;
        // animation: fadeicons .3s linear ;
      }
      //   flex: 0 0 auto;
    }
    position: relative;
    .menu_main {
      position: absolute;
      bottom: calc(100% + 10px);
      left: 0;
      background: #fff;
      border: 1px solid var(--grey40);
      width: 132px;
      display: flex;
      flex-wrap: wrap;
      column-gap: 10px;
      gap: 10px;
      padding: 10px;
      border-radius: 5px;
      transform: scale(0);
      transition: all 0.2s linear;
      transform-origin: bottom left;
      &.active {
        transform: scale(1);
      }
      .icons {
        svg {
          padding: 10px;
          border: 1px solid var(--grey30);
          height: 50px;
          width: 50px;
          border-radius: 5px;
          //   fill: var(--grey30);
          > path {
            // fill: var(--grey30);
          }
        }
        &.active {
          svg {
            border-color: var(--black);
            box-shadow: inset 0 0 0 2px var(--black);
          }
        }
      }
      .clear {
        cursor: pointer;
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--grey30);
        border-radius: 5px;
        &.active {
          border-color: var(--black);
          box-shadow: inset 0 0 0 2px var(--black);
        }
        > div {
          height: 30px;
          width: 30px;
          border: 5px solid var(--red);
          border-radius: 50%;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            inset: 0;
            margin: auto;
            width: 5px;
            background: var(--red);
            transform: rotate(-45deg);
          }
        }
      }
    }
    // > div {
    //   height: 5px;
    //   width: 5px;
    //   max-width: 5px;
    //   border-radius: 5px;
    //   //   background: #000;

    // }
  }
}
