.preview {
  position: absolute;
  inset: 0;
  background: red;
  border-radius: 10px;
  // border: 1px solid var(--white);
  // margin: -1px;
  // z-index: -1;
  box-shadow: inset 0 0 0 2px var(--white);
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://julietteimages.s3.amazonaws.com/systemimages/faq-min.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  &.active {
    transform: translateY(0);
  }
  .preview_navbar {
    padding: 20px;
    img {
      width: 100px;
    }
  }
  .preview_body {
    padding: 20px;
    color: var(--white);
    // border: 1px solid red;
    h1,
    h2,
    h3 {
      line-height: 1;
      margin: 0;
    }
    ul,
    ol {
      margin: 10px 0 10px 0;
    }
    > div {
      display: flex;
      > div {
        &:first-child {
          width: 20px;
          text-align: right;
          margin: 0 10px 0 0;
        }
        &:last-child {
          flex-grow: 1;
          color: var(--white) !important;
          .rte .rte_editor {
            margin: 0;
            padding: 0;
            border: 0;
          }
        }
      }
    }
    .question {
      font-size: 16px;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      margin: 0 0 20px 0;
      text-transform: uppercase;
    }
    .answer {
      font-size: 16px;
      line-height: 21px;
    }
    a {
      all: unset;
      color: var(--black);
    }
    .element-link {
      background: var(--white);
    }
    .links_group {
      // border: 1px solid red;
      display: flex;
      flex-direction: column;
      .link_btn_items {
        padding: 10px 20px;
        background: #fff;
        width: fit-content;
        margin: 20px 0 0 30px;
        border-radius: 4px;
        color: #000;
        font-weight: 700;
        .link_btn {
          display: flex;
          column-gap: 10px;
          .link_img {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }
}
