.itemspage {
  display: flex;
  flex-direction: column;
  &.pt-0 {
    padding-top: 0px !important;
    // justify-content: center;
    align-items: center;
    // padding-block: 40px;
    // .items_card_body {
    //   width: 100vw;
    // }
  }
  .items_card_body {
    // max-height: 90vh;
    // border: 1px solid red;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
    padding: 0 !important;
    height: calc(100% - 80px);
    // padding-right: 90px !important;
    width: 100%;
    &.columns_layout {
      width: 100vw;
      display: flex;
      // column-count: 2;
      // display: flex;
      padding: 10px 90px !important; // padding-left: 90px !important;
      flex-direction: row;
      flex-wrap: wrap;

      .itemcards {
        // display: inline-block;
        margin: 0px auto 20px auto;
        // transform: translateY(10px);
        width: calc(51%);
      }
    }
  }
  .itemcards {
    -webkit-column-break-inside: avoid;
    // width: 100%;
    // flex: 0 auto 103px !important;
    width: 50%;
    min-width: fit-content;
    height: 83px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--black);
    border-radius: var(--border-radius-6);
    font-weight: 500;
    font-size: var(--f30);
    line-height: var(--lh39);
    position: relative;
    cursor: pointer;
    margin: 0 auto;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &.add_card {
      background: var(--black);
    }
    &.view_card {
      background: transparent;
      height: 0px;
      border: 0;
      margin: 0;
    }
    .itemadd {
      cursor: auto;
      // * {
      //     cursor: auto;
      // }
      // height: 100%;
      // width: 100%;
      position: fixed;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--modal-bg);
      z-index: 100;
      > div {
        width: 298px;
        height: 397px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: var(--white);
        position: relative;
        border: 1px solid var(--black);
        border-radius: var(--border-radius-12);
        padding: 41px 20px 24px 38px;
        .head {
          svg {
            position: absolute;
            right: 16px;
            top: 25px;
          }
        }
        .body {
          padding-right: 53px;
          display: flex;
          flex-direction: column;
          .title {
            font-weight: 700;
            font-size: var(--f16);
            line-height: var(--lh21);
            margin-bottom: 6px;
          }
          input {
            height: 31px;
            padding-inline: 10px;
            width: 100%;
            border: 1px solid var(--black);
            border-radius: var(--border-radius-4);
            font-size: var(--f14);
            line-height: var(--lh18);
            font-weight: 500;
            margin-bottom: 14px;
            &::placeholder {
              color: var(--black);
            }
          }

          .custoptionselect {
            // height: 100%;
            // width: 170px;
            font-size: var(--f20);
            line-height: var(--lh21);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            // .cust_selector {
            //     height: 16px;
            //     width: 28px;
            //     margin-right: 10px;
            //     background: var(--modal-bg);
            //     border-radius: 14px;
            // }
            .checkbox {
              height: 15px;
              width: 15px;
              border: 1px solid var(--black);
              border-radius: var(--border-radius-4);
              margin-right: 10px;
              position: relative;
              &::after {
                position: absolute;
                content: "";
                inset: 0;
                height: 100%;
                width: 100%;
                margin: auto;
                // border-radius: var(--border-radius-4);
                background: var(--black);
                transform: scale(0);
                transition: transform 0.3s linear;
              }
              &.checkbox_active {
                &::after {
                  transform: scale(1);
                }
              }
            }
            .title {
              font-weight: 400;
              width: calc(100% - 15px);
            }
          }
        }
        // .action_btns {
        //     // position: absolute;
        //     left: calc(100% + 20px);
        //     height: 100%;
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: space-around;
        //     align-items: center;
        // }
        .footer {
          margin-left: auto;
          margin-top: auto;
          display: flex;
          column-gap: 10px;
          .btn {
            width: 100px;
            height: 31px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: var(--f12);
            line-height: var(--lh16);
          }
        }
      }
    }
  }
  .eye_off {
    // width: 25px;
    // height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    > svg {
      --svg_current_color: var(--grey40) !important;
    }
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 2px;
      background: var(--grey40);
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      transform: rotate(45deg);
    }
  }
  .itemsubcategory {
    // padding-right: 109px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .header {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 700;
      font-size: var(--f40);
      line-height: var(--lh52);
      margin-bottom: 31px;
      .go_back {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          margin-right: 21px;
        }
      }
      .add_subcategory {
        height: 100%;
        aspect-ratio: 1;
        background: var(--black);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: var(--border-radius-6);
      }
    }
    > .body {
      height: calc(100vh - 176px);
      overflow: auto;
    }
  }
  .items_delete_modal {
    position: fixed;
    inset: 0;
    height: 100vh;
    background: var(--modal-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    > .items_delete_modal_main {
      background: var(--white);
      width: 400px;
      height: 200px;
      border-radius: var(--border-radius-12);
      border: 1px solid var(--black);
      padding: 20px;
      display: flex;
      flex-direction: column;
      > .header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      > .body {
        margin: auto;
        font-size: var(--f20);
        font-weight: 700;
      }
      > .footer {
        margin: auto 0 0 0;
        display: flex;
        column-gap: 10px;
        > div {
          flex-grow: 1;
        }
      }
    }
  }
  .items_note {
    position: fixed;
    // margin: auto 0 0 0;
    // top: 0;
    // background: red;
    // z-index: 100;
    bottom: 0;
    left: 0;
    right: 0;
    // background: red;
    width: 100%;
    height: 80px;
    border-top: 1px solid var(--modal-bg);
    // border-bottom-right-radius: var(--border-radius-12);
    // border-bottom-left-radius: var(--border-radius-12);
    // padding: 10px 90px;
    // padding-left: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-direction: column;
    column-gap: 20px;
    .note {
      font-weight: 700;
    }
    .tick_group {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
    .items_note_flex {
      height: 30px;
      display: flex;
      align-items: center;
      // justify-content: center;
      column-gap: 10px;
      // > div {
      //   width: 50%;
      // }
    }
  }
}
