.resetpasswordteammodal {
  position: fixed;
  inset: 0;
  background: var(--modal-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    background: var(--white);
    width: 334px;
    height: 436px;
    border: 1px solid var(--black);
    border-radius: 12px;
    padding: 41px 26px 22px 38px;
    position: relative;
    display: flex;
    flex-direction: column;
    .header {
      position: absolute;
      top: 25px;
      right: 22px;
    }
    .body {
      .title {
        font-weight: 700;
        font-size: var(--f16);
        line-height: var(--lh21);
        letter-spacing: 0.9975px;
        margin: 0 0 6px 0;
        &.admin_switch {
          display: flex;
          column-gap: 20px;
          align-items: center;
          margin: 20px 0 6px 0;
          // width: 100%;
        }
      }
      .cust_inp {
        width: 191px;
        height: 31px;
        border: 1px solid #000000;
        border-radius: 4px;
        overflow: hidden;
        margin: 0 0 14px 0;
        input {
          border: 0;
          width: 100%;
          height: 100%;
          padding: 0 10px;
          font-weight: 500;
          font-size: var(--f14);
          line-height: var(--lh18);
          letter-spacing: 0.9975px;
          &::placeholder {
            color: var(--black);
            font-weight: 500;
            font-size: var(--f14);
            line-height: var(--lh18);
            letter-spacing: 0.9975px;
          }
        }
      }
    }
    .footer {
      margin: auto 0 0 0;
      .btn {
        width: 120px;
        height: 31px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: var(--f12);
        line-height: var(--lh16);
        letter-spacing: 0.9975px;
        margin: 0 0 0 auto;
      }
    }
  }
}
