.addorderimages {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 139px);
  .addorderimages_title {
    margin-top: 29px;
    font-size: var(--f20);
    line-height: var(--lh26);
    font-weight: 500;
  }
  .addorderimages_group {
    width: calc(100% + 27px);
    height: 100vh;
    overflow-y: scroll;
    margin-top: 12px;
    margin-right: -27px;
    .addorderimages_title {
      margin-block: 22px 12px;
    }
    .images_set_main_body {
      display: flex;
      --size-height-image-container: 58px;
      --size-width-image-container: 84px;
      --margin-right-image-container: 20px;
      > textarea {
        resize: none;
        width: calc(
          100% - var(--size-width-image-container) -
            var(--margin-right-image-container)
        );
        // width: 10%;
        padding: 12px 16px;
        border: 1px solid var(--grey30);
        font-size: var(--f16);
        line-height: var(--lh21);
        max-height: var(--size-height-image-container);
        border-radius: var(--border-radius-6);
      }
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      .addorderimages_img_set {
        width: var(--size-width-image-container);
        height: var(--size-height-image-container);
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--black);
        border-radius: var(--border-radius-6);
        overflow: hidden;
        margin-right: var(--margin-right-image-container);
        border: 1px solid var(--black);

        input {
          display: none;
          // width: 100%;
        }
        .images {
          height: 100%;
          position: relative;
          img {
            height: 100%;
          }
          .images_overlay {
            cursor: pointer;
            position: absolute;
            inset: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--white);
            background: var(--modal-bg);
            font-size: var(--f30);
            line-height: var(--lh39);
            text-shadow: 0px 0px 10px var(--black);
          }
        }
        .camera {
          height: 28px;
          width: 28px;
        }
      }
    }
    .delete_img_note {
      margin-left: 7px;
    }
  }
  .global_discount {
    margin-bottom: 10px;
    // background: red;
    display: flex;
    align-items: flex-end;
    // align-items: center;
    column-gap: 20px;
    > div {
      width: 50%;
    }
    .addorderimages_title {
      margin-bottom: 13px;
    }
    .discount_group {
      display: flex;
      column-gap: 10px;
      > div {
        // width: 50%;
      }
      .discountinput_group {
        border: 1px solid var(--grey30);
        height: 46px;
        border-radius: var(--border-radius-6);
        // width: 50%;
        flex-grow: 1;

        display: flex;
        > input {
          border: 0;
          font-weight: 500;
          font-size: var(--f16);
          padding: 0;
          width: calc(100% - 21px);
          padding-inline: 14px;
          font-size: var(--f16);
          line-height: var(--lh21);
          // padding-block: 13px 12px;
          &::placeholder {
            color: var(--black);
          }
        }
        > div {
          font-size: var(--f14);
          line-height: var(--lh18);
          width: 21px;
          flex-basis: 1;
          display: flex;
          align-items: center;
          // text-align: center;
          overflow: hidden;
          transition: width 0.3s linear;
          &:first-child {
            justify-content: flex-end;
          }
          &:last-child {
            justify-content: flex-start;
          }
        }
        &[show="dollar"] {
          > div:first-child {
            width: 21px;
          }
          > div:last-child {
            width: 0;
          }
        }
        &[show="percentage"] {
          > div:first-child {
            width: 0px;
          }
          > div:last-child {
            width: 21px;
          }
        }
      }
      .switch_convert_dollar_and_percent {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .main_bg {
          cursor: pointer;
          height: 16px;
          width: 28px;
          background: var(--grey50);
          border-radius: var(--border-radius-8);
          position: relative;
          .slider_btn {
            position: absolute;
            top: 2px;
            height: 12px;
            width: 12px;
            background: var(--white);
            border-radius: 50%;
            transition: all 0.3s linear;
            &[show="percentage"] {
              left: 2px;
              // margin: 2px 2px 2px auto;
            }
            &[show="dollar"] {
              left: calc(100% - 2px - 12px);
              // margin: 2px auto 2px 2px;
            }
          }
        }
      }
    }
    .customer_discount_code {
      font-weight: 500;
      font-size: var(--f16);
      line-height: var(--lh21);
      color: var(--grey50);
    }
  }

  .image_btn_group {
    display: flex;
    column-gap: 20px;
    margin-top: auto;
    font-weight: 500;
    > .btn {
      width: 100%;
    }
  }
}
