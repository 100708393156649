:root {
  // fontsize
  // lineheight respectively
  --f9: 9px;

  --f10: 10px;
  --lh12: 12px;

  --lh12: 13px;

  --f12: 12px;
  --lh16: 16px;

  --f13: 13px;
  --lh17: 17px;

  --f14: 14px;
  --lh18: 18px;

  --f18: 18px;

  --lh19: 19px;

  --f16: 16px;
  --lh21: 21px;

  --lh22: 22px;

  --f20: 20px;
  --lh26: 26px;

  --f24: 24px;
  --lh31: 31px;

  --f25: 25px;
  --lh33: 33px;

  --f26: 26px;
  --lh34: 34px;

  --f30: 30px;
  --lh39: 39px;

  --f35: 35px;
  --lh46: 46px;

  --f40: 40px;
  --lh52: 52px;

  --f50: 50px;
  --lh65: 65px;
}
