.add_vendor {
  position: fixed;
  inset: 0;
  background: var(--modal-bg);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    width: 334px;
    height: 436px;
    background: var(--white);
    border-radius: var(--border-radius-12);
    border: 1px solid #000000;
    padding: 25px 22px 22px 38px;
    display: flex;
    flex-direction: column;
    .header {
      // position: relative;
      display: flex;
      justify-content: flex-end;
    }
    .body {
      display: flex;
      flex-direction: column;
      gap: 14px;
      .title {
        font-weight: 700;
        font-size: var(--f16);
        line-height: var(--lh21);
        letter-spacing: 0.9975px;
        margin: 0 0 6px 0;
      }
      .cust_input {
        width: 191px;
        height: 31px;
        border: 1px solid var(--black);
        border-radius: var(--border-radius-4);
        overflow: hidden;
        input,
        textarea {
          width: 100%;
          height: 100%;
          border: 0;
          resize: none;
          padding: 7px 10px;
          font-weight: 500;
          font-size: var(--f14);
          line-height: var(--lh18);
          letter-spacing: 0.9975px;
          &::placeholder {
            color: var(--black);
            font-weight: 500;
            font-size: var(--f14);
            line-height: var(--lh18);
            letter-spacing: 0.9975px;
          }
        }
        &.with_textarea {
          height: 56px;
        }
      }
    }
    .action_btn_group {
      display: flex;
      justify-content: flex-end;
      margin: auto 4px 0 0;
      .btn {
        height: 31px;
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--f12);
        line-height: var(--lh16);
        letter-spacing: 0.9975px;
      }
    }
  }
}
