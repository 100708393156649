.custdropdown {
  height: 31px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: var(--f14);
  line-height: var(--lh18);
  cursor: pointer;
  transition: all 0.3s linear;
  // z-index: 0;
  position: relative;
  .default_txt {
    padding-inline: 10px 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--border-radius-4);
    border: 1px solid var(--grey30);
    width: 100%;
    height: 100%;
    // z-index: 1010;
    background: var(--white);
    > div {
      padding-inline: 0 5px 0 0;
      max-width: calc(100% - 14px - (13 * 2) - 5px);
      overflow: hidden;
      //   background: red;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  // &.custdropdown_active {
  //     border-color: var(--black);
  //     border-bottom: 1px solid transparent;
  //     border-bottom-left-radius: 0;
  //     border-bottom-right-radius: 0;
  // }
  .dropdown_arrow_down {
    height: 14px;
    width: 14px;
    transition: all 0.3s linear;
  }
  .dropdown_body {
    position: absolute;
    top: 100%;
    left: 0px;
    left: 0px;
    // right: -1px;
    width: calc(100% + 2px);
    width: 100%;
    // border-bottom-right-radius: var(--border-radius-4);
    // border-bottom-left-radius: var(--border-radius-4);
    background: var(--white);
    transition: all 0.3s linear;
    // margin-inline: -2px;
    // opacity: 0;
    overflow: hidden;
    z-index: 100;
    // margin-top: 0px;
    border-radius: var(--border-radius-4);
    overflow: hidden;
    margin: -3px 0 0 0;
    // z-index: 10;
    > div {
      // position: relative;
      border-radius: var(--border-radius-4);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border: 1px solid var(--black);
      padding-block: 5px;
      border-top: 0;
      .dropdown_item {
        padding-block: 6px;
        padding-inline: 20px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        &.dropdown_item_active {
          font-weight: 700;
        }
      }
      > div {
        > #input_cust {
          // position: absolute;
          // position: relative;
          // border: 1px solid red;
          height: 30px;
          width: 100%;
          border: 1px solid var(--grey30);
          border-radius: var(--border-radius-4);
          // position: static;
          // top: 0;
          // left: 0;
        }
      }
      .add_items {
      }
    }
  }
}
