.custdatepicker {
    height: 31px;
    border: 1px solid var(--grey30);
    border-radius: var(--border-radius-4);
    font-weight: 500;
    font-size: var(--f14);
    line-height: var(--lh18);
    // z-index: 0;
    position: relative;
    .default_txt {
        cursor: pointer;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-inline: 10px 13px;
        // z-index: 100;
        // z-index: -1;
    }
    .dropdown_arrow_down {
        height: 14px;
        width: 14px;
        transition: all 0.3s linear;
    }
    .calender_main {
        z-index: 100;
        position: absolute;
        top: 16px;
        top: 100%;
        left: calc(50% - 1px);
        right: 0;
        width: fit-content;
        margin: auto;
        transform: translateX(calc(-50% + 17px));
        overflow: hidden;
        transition: height 0.3s linear;
        .rdrCalendarWrapper {
            transform: translateY(-13px);
            // height: 0px;
            border: 1px solid var(--black);
            border-radius: var(--border-radius-4);
            // height: 294px;
            z-index: 100;
        }
    }
}
